import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

export interface Country {
  name: string;
  isoCode: string;
}

export interface State {
  name: string;
  isoCode: string;
}

export interface City {
  name: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountryStatesCitiesService {
  private generateApiUrl = (path: string) => environment.apiURL + path;

  public _countries: BehaviorSubject<Country[]> = new BehaviorSubject(null);
  public _states: BehaviorSubject<State[]> = new BehaviorSubject(null);
  public _cities: BehaviorSubject<City[]> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get countries$(): Observable<Country[]> {
    return this._countries.asObservable();
  }

  get states$(): Observable<State[]> {
    return this._states.asObservable();
  }

  get cities$(): Observable<City[]> {
    return this._cities.asObservable();
  }

  getCountries(forceRefresh = false): Observable<Country[]> {
    return this.countries$.pipe(
      take(1),
      switchMap((countries) => {
        // Donot call the API if countries already exists
        if (!forceRefresh && countries && countries.length > 0) {
          return of(countries);
        }
        return this._httpClient.get<Country[]>(this.generateApiUrl('country')).pipe(
          tap((response) => {
            this._countries.next(response);
          })
        );
      })
    );
  }

  getStates(countryCode: string): Observable<State[]> {
    return this._httpClient.get<State[]>(this.generateApiUrl('state'), { params: { countryCode } }).pipe(
      tap((response) => {
        this._states.next(response);
      })
    );
  }

  getCities(countryCode: string, stateCode: string): Observable<City[]> {
    return this._httpClient.get<City[]>(this.generateApiUrl('city'), { params: { countryCode, stateCode } }).pipe(
      tap((response) => {
        this._cities.next(response);
      })
    );
  }
}
