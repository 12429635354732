import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseCardModule } from '@fuse/components/card';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';
import { SideDesignComponent } from 'app/modules/auth/side-design/side-design.component';
import { StripeCardDetailsComponent } from 'app/shared/stripe-card/stripe-card.component';
import { environment } from 'environments/environment';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NgxStripeModule } from 'ngx-stripe';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { MusicPlayerComponent } from './music-player/music-player.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { ShareInviteComponent } from './share-invite/share-invite.component';
import { ShowTextDialogComponent } from './showText-dialog/showText-dialog.component';
import { StripeBalanceComponent } from './stripe-balance/stripe-balance.component';
import { StripeConnectComponent } from './stripe-connect/stripe-connect.component';
import { TrophyBadgeComponent } from './trophy-badge/trophy-badge.component';
import { AddEditAddressDialogComponent } from './add-edit-address-dialog/add-edit-address-dialog.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    MatSliderModule,
    MatTabsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,

    FuseCardModule,
    MtxPopoverModule,
    ShareButtonsModule,
    ShareIconsModule,

    LazyLoadImageModule,
    InfiniteScrollModule,

    NgxStripeModule.forRoot(environment.stripePublishableKey),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageUploaderComponent,
    ImageViewerComponent,
    MusicPlayerComponent,
    StripeCardDetailsComponent,
    StripeConnectComponent,
    StripeBalanceComponent,
    DateAgoPipe,
    NameInitialsPipe,
    OrdinalPipe,
    ShareInviteComponent,
    SideDesignComponent,
    TrophyBadgeComponent,
    ProgressSpinnerComponent,
  ],
  declarations: [
    ImageUploaderComponent,
    ImageViewerComponent,
    MusicPlayerComponent,
    StripeCardDetailsComponent,
    StripeConnectComponent,
    ShowTextDialogComponent,
    StripeBalanceComponent,
    DateAgoPipe,
    NameInitialsPipe,
    OrdinalPipe,
    ShareInviteComponent,
    SideDesignComponent,
    TrophyBadgeComponent,
    ProgressSpinnerComponent,
    AddEditAddressDialogComponent,
  ],
  providers: [OrdinalPipe],
})
export class SharedModule {}
