import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Music, StreamState } from '../models';
import { UserService } from './user.service';
import moment from 'moment';

export interface ApiResponse {
  data: Music[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class MusicService {
  private generateApiUrl = (path: string) => environment.apiURL + 'music/' + path;

  _allMusic: BehaviorSubject<ApiResponse | null> = new BehaviorSubject(null);

  private stop$ = new Subject();
  private audioObj = new Audio();
  audioEvents = ['ended', 'error', 'play', 'playing', 'pause', 'timeupdate', 'canplay', 'loadedmetadata', 'loadstart'];
  private state: StreamState = {
    playing: false,
    readableCurrentTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    canplay: false,
    error: false,
    showPlayer: false,
  };
  stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.state);

  currentMusic: { music: Music; index: number };

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get allMusic$(): Observable<ApiResponse> {
    return this._allMusic.asObservable();
  }

  //   get currentMusic$(): Observable<Music> {
  //     return this.currentMusic.asObservable();
  //   }

  get musics(): Music[] {
    return this._allMusic.value ? this._allMusic.value.data : [];
  }

  uploadMusic(pageid: string, data: any): Observable<{ message: string; news: Music }> {
    return this._httpClient.post<{ message: string; news: Music }>(
      this.generateApiUrl(`uploadMusic?pageid=${pageid}`),
      data
    );
  }

  getAllMusic(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    pageid = '',
    name = ''
  ): Observable<ApiResponse> {
    return this._httpClient
      .get<ApiResponse>(this.generateApiUrl('getAll'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          pageid: pageid || this._userService.userObj.page,
          name,
        },
      })
      .pipe(
        tap((response) => {
          this._allMusic.next(response);
        })
      );
  }

  public deleteMusic(data: any) {
    return this.allMusic$.pipe(
      take(1),
      switchMap((music) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl('delete'), { body: data }).pipe(
          map((response) => {
            const index = music.data.findIndex((item) => item._id === data.musicId);

            music.data.splice(index, 1);

            if (this.currentMusic && data.musicId === this.currentMusic.music._id) {
              this.hidePlayer();
            }

            this._allMusic.next(music);
            return response;
          })
        )
      )
    );
  }

  //   =====Methods for music player starts=====
  private streamObservable(url) {
    return new Observable((observer) => {
      // Play audio
      this.audioObj.src = url;
      this.audioObj.load();
      this.audioObj.play();

      const handler = (event: Event) => {
        this.updateStateEvents(event);
        observer.next(event);
      };

      this.addEvents(this.audioObj, this.audioEvents, handler);
      return () => {
        // Stop Playing
        this.audioObj.pause();
        this.audioObj.currentTime = 0;
        // remove event listeners
        this.removeEvents(this.audioObj, this.audioEvents, handler);
        // reset state
        this.resetState();
      };
    });
  }

  private addEvents(obj, events, handler) {
    events.forEach((event) => {
      obj.addEventListener(event, handler);
    });
  }

  private removeEvents(obj, events, handler) {
    events.forEach((event) => {
      obj.removeEventListener(event, handler);
    });
  }

  playStream(url) {
    return this.streamObservable(url).pipe(takeUntil(this.stop$));
  }

  play() {
    this.audioObj.play();
  }

  pause() {
    this.audioObj.pause();
  }

  stop() {
    this.stop$.next();
  }

  seekTo(seconds) {
    this.audioObj.currentTime = seconds;
  }

  formatTime(time: number, format: string = 'HH:mm:ss') {
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }

  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case 'canplay':
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        break;
      case 'playing':
        this.state.playing = true;
        break;
      case 'pause':
        this.state.playing = false;
        break;
      case 'timeupdate':
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        break;
      case 'error':
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

  resetState() {
    this.state = {
      playing: false,
      readableCurrentTime: '',
      readableDuration: '',
      duration: undefined,
      currentTime: undefined,
      canplay: false,
      error: false,
      showPlayer: false,
    };
    return this.state;
  }

  showPlayer() {
    this.state.showPlayer = true;
    this.stateChange.next(this.state);
  }

  hidePlayer() {
    this.stop();
    this.resetState();
    this.currentMusic = null;
    this.stateChange.next(this.state);
  }

  getState(): Observable<StreamState> {
    return this.stateChange.asObservable();
  }
  //   =====Methods for music player ends=====
}
