import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { News } from '../models';
import { UserService } from './user.service';

export interface NewsApiResponse {
  data: News[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  private generateApiUrl = (path: string) => environment.apiURL + 'news/' + path;

  public _news: BehaviorSubject<News> = new BehaviorSubject<News>(null);
  private _allNews: BehaviorSubject<NewsApiResponse | null> = new BehaviorSubject(null);
  public _isSubmittingForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get news$(): Observable<News> {
    return this._news.asObservable();
  }

  get allNews$(): Observable<NewsApiResponse> {
    return this._allNews.asObservable();
  }

  getNews(id: string): Observable<{ message: string; news: News }> {
    return this._httpClient.get<{ message: string; news: News }>(this.generateApiUrl(`single/${id}`)).pipe(
      tap((response) => {
        this._news.next(response.news);
      })
    );
  }

  create(data: any): Observable<{ message: string; news: News }> {
    return this._httpClient.post<{ message: string; news: News }>(this.generateApiUrl('create'), data);
  }

  getAllNews(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    pageid = '',
    title = ''
  ): Observable<NewsApiResponse> {
    return this._httpClient
      .get<NewsApiResponse>(this.generateApiUrl('getAll'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          pageid: pageid || this._userService.userObj.page,
          title,
        },
      })
      .pipe(
        tap((response) => {
          this._allNews.next(response);
        })
      );
  }

  update(data: any): Observable<{ message: string; news: News }> {
    return this._httpClient.put<{ message: string; news: News }>(this.generateApiUrl('update'), data).pipe(
      tap((response) => {
        this._news.next(response.news);
      })
    );
  }

  public deleteNews(data: any) {
    return this.allNews$.pipe(
      take(1),
      switchMap((news) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl('del'), { body: data }).pipe(
          map((response) => {
            const index = news.data.findIndex((item) => item._id === data.newsID);

            news.data.splice(index, 1);

            this._allNews.next(news);
            return response;
          })
        )
      )
    );
  }
}
