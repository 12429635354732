import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Order } from '../models/order.model';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProductOrderService {
  private generateApiUrl = (path: string) => environment.apiURL + 'productOrder/' + path;

  _myOrders: BehaviorSubject<any | null> = new BehaviorSubject(null);
  _mySales: BehaviorSubject<any | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  get myOrders$(): Observable<any> {
    return this._myOrders.asObservable();
  }

  get mySales$(): Observable<any> {
    return this._mySales.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  create(data: { addressID: string; productID: string; variantID: string; orderId?: string }): Observable<{
    message: string;
    order: Order;
    stripeOrderObj: any;
  }> {
    return this._httpClient.post<{
      message: string;
      order: Order;
      stripeOrderObj: any;
    }>(this.generateApiUrl('create'), data);
  }

  submit(orderId: string): Observable<{
    // eslint-disable-next-line @typescript-eslint/naming-convention
    client_secret: string;
  }> {
    return this._httpClient.post<{
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_secret: string;
    }>(this.generateApiUrl('submit'), { orderId });
  }

  updateStatus(data: {
    status: 'succeeded' | 'failed';
    orderId: string;
  }): Observable<{ message: string; status?: string }> {
    return this._httpClient.post<{ message: string; status?: string }>(this.generateApiUrl('updateOrderStatus'), data);
  }

  getOrder(orderID: string): Observable<Order> {
    return this._httpClient.get<Order>(this.generateApiUrl(`details/${orderID}`));
  }

  myOrders(pageNo: number, size: number, sortBy: string, sortDirection: string, status = ''): Observable<any> {
    return this.myOrders$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .get<any>(this.generateApiUrl('myOrder'), {
            params: {
              pageNo,
              size,
              sortBy,
              sortDirection,
              isSeller: false,
              status,
            },
          })
          .pipe(
            tap((response) => {
              if (records) {
                response.data = records.data.concat(response.data);
              }
              this._myOrders.next(response);
              return response;
            })
          )
      )
    );
  }

  mySales(pageNo: number, size: number, sortBy: string, sortDirection: string): Observable<any> {
    return this._httpClient
      .get<any>(this.generateApiUrl('myOrder'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          isSeller: true,
        },
      })
      .pipe(
        tap((response) => {
          this._mySales.next(response);
          return response;
        })
      );
  }

  resetMyOrders() {
    this._myOrders.next(null);
  }

  getInvoice(name: string, orderId: string) {
    return this._httpClient.get(this.generateApiUrl(`invoice/${name}`), {
      params: {
        orderId,
      },
      responseType: 'blob',
      observe: 'response',
    });
  }
}
