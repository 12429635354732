<div class="relative flex flex-col md:w-128 -m-6">
  <!-- Dismiss button -->
  <ng-container>
    <div class="absolute top-0 right-0 pt-4 pr-4">
      <button mat-icon-button [matDialogClose]="undefined" [disabled]="form.disabled">
        <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
      </button>
    </div>
  </ng-container>

  <!-- Content -->
  <div class="flex flex-col min-w-full items-center p-8 pb-6 sm:pb-8">
    <div class="flex text-3xl leading-6 font-bold">{{ data.type }}</div>

    <!-- Alert -->
    <fuse-alert
      *ngIf="showAlert"
      [appearance]="'outline'"
      [showIcon]="false"
      [type]="alert.type"
      [@shake]="alert.type === 'error'"
      [dismissible]="true"
      [dismissed]="false"
    >
      {{ alert.message }}
    </fuse-alert>

    <!-- Address Form -->
    <form class="w-full mt-4" [formGroup]="form" (ngSubmit)="save()" #dataForm="ngForm">
      <div class="flex flex-col sm:flex-row gap-3">
        <!-- Name -->
        <mat-form-field class="w-full sm:w-1/2" [floatLabel]="'always'">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Enter name" [formControlName]="'name'" required />
          <mat-error *ngIf="form.get('name').hasError('required')">Name is required </mat-error>
        </mat-form-field>

        <!-- Mobile no. -->
        <mat-form-field class="w-full sm:w-1/2" [floatLabel]="'always'">
          <mat-label>Mobile No.</mat-label>
          <input matInput placeholder="Enter mobile no." formControlName="mobile" required />
          <!-- <mat-hint>Enter 10 digit mobile no.</mat-hint> -->
          <mat-error *ngIf="form.get('mobile').hasError('required') && form.get('mobile').touched">
            This field should not be empty.
          </mat-error>
          <mat-error *ngIf="form.get('mobile').hasError('pattern') && form.get('mobile').touched">
            Please enter 10 digit mobile no.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row gap-3">
        <!-- Name -->
        <mat-form-field class="w-full" [floatLabel]="'always'">
          <mat-label>Country</mat-label>
          <mat-select formControlName="country" [value]="''" placeholder="Select Country" aria-required="true" required>
            <mat-option *ngFor="let country of countries$ | async" [value]="country.isoCode">
              {{ country.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('country').hasError('required')">Country is required </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row gap-3">
        <!-- Address test box -->
        <mat-form-field class="w-full" [floatLabel]="'always'">
          <mat-label>Address Line 1</mat-label>
          <input matInput placeholder="Enter Address Line 1" formControlName="addressLine" required />
          <mat-error *ngIf="form.get('addressLine').hasError('required') && form.get('addressLine').touched">
            Address is required
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row gap-3">
        <!-- City -->
        <mat-form-field class="w-full sm:w-1/2" [floatLabel]="'always'">
          <mat-label>City</mat-label>
          <input matInput placeholder="Enter City" formControlName="city" required />
          <mat-error *ngIf="form.get('city').hasError('required') && form.get('city').touched">
            City is required.
          </mat-error>
        </mat-form-field>

        <!-- Pincode -->
        <mat-form-field class="w-full sm:w-1/2" [floatLabel]="'always'">
          <mat-label>Zipcode</mat-label>
          <input matInput placeholder="Enter Zipcode" formControlName="pin" required />
          <mat-error *ngIf="form.get('pin').hasError('required') && form.get('pin').touched">
            This field should not be empty.
          </mat-error>
          <mat-error *ngIf="form.get('pin').hasError('pattern') && form.get('pin').touched">
            Please enter 5 digit Zipcode
          </mat-error>
        </mat-form-field>
      </div>

      <div class="flex flex-col sm:flex-row gap-3">
        <!-- State -->
        <mat-form-field class="w-full sm:w-1/2" [floatLabel]="'always'">
          <mat-label>State</mat-label>
          <mat-select formControlName="state" placeholder="Select state" aria-required="true" required>
            <mat-option *ngFor="let state of states$ | async" [value]="state.isoCode">
              {{ state.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('state').hasError('required') && form.get('state').touched">
            State is required.
          </mat-error>
        </mat-form-field>
        <!-- Landmark -->
        <mat-form-field class="w-full sm:w-1/2" [floatLabel]="'always'">
          <mat-label>Landmark</mat-label>
          <input matInput placeholder="Enter Landmark" formControlName="landmark" />
        </mat-form-field>
      </div>
    </form>
  </div>

  <!-- Actions -->
  <ng-container>
    <div
      class="
        flex
        items-center
        justify-center
        sm:justify-start
        px-6
        py-4
        space-x-3
        bg-gray-50
        dark:bg-black dark:bg-opacity-10
      "
    >
      <button mat-raised-button (click)="close()" [disabled]="form.disabled">Close</button>

      <button class="mat-raised-button" mat-button [color]="'primary'" [disabled]="form.disabled" (click)="save()">
        <span *ngIf="!form.disabled">
          {{ data.type === 'Add' ? 'Submit' : 'Update' }}
        </span>
        <mat-progress-spinner *ngIf="form.disabled" [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
      </button>
    </div>
  </ng-container>
</div>
