import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Bookmark } from '../models';

export interface BookmarkApiResponse {
  data: Bookmark[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class BookmarkService {
  private generateApiUrl = (path: string) => environment.apiURL + 'bookmark/' + path;

  public _bookmark: BehaviorSubject<Bookmark> = new BehaviorSubject<Bookmark>(null);
  private _bookmarks: BehaviorSubject<BookmarkApiResponse | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get bookmark$(): Observable<Bookmark> {
    return this._bookmark.asObservable();
  }

  get bookmarks$(): Observable<BookmarkApiResponse> {
    return this._bookmarks.asObservable();
  }

  getBookmark(id: string): Observable<{ message: string; bookmark: Bookmark }> {
    return this._httpClient.get<{ message: string; bookmark: Bookmark }>(this.generateApiUrl(`single/${id}`)).pipe(
      tap((response) => {
        this._bookmark.next(response.bookmark);
      })
    );
  }

  create(data: any): Observable<{ message: string; bookmark: Bookmark }> {
    return this._httpClient.post<{ message: string; bookmark: Bookmark }>(this.generateApiUrl('create'), data);
  }

  getAllBookmarks(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    name = ''
  ): Observable<BookmarkApiResponse> {
    return this._httpClient
      .get<BookmarkApiResponse>(this.generateApiUrl('getAll'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          name,
        },
      })
      .pipe(
        tap((response) => {
          this._bookmarks.next(response);
          return response;
        })
      );
  }

  update(data: {
    bookmarkId: string;
    name: string;
    link: string;
  }): Observable<{ message: string; bookmark: Bookmark }> {
    return this.bookmarks$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient.put<{ message: string; bookmark: Bookmark }>(this.generateApiUrl('update'), data).pipe(
          map((updateData) => {
            const index = records.data.findIndex((item) => item._id === data.bookmarkId);

            records.data[index] = updateData.bookmark;

            this._bookmarks.next(records);

            return updateData;
          })
        )
      )
    );
  }

  public delete(data: { id: string }) {
    return this.bookmarks$.pipe(
      take(1),
      switchMap((record) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl('del'), { body: data }).pipe(
          map((response) => {
            const index = record.data.findIndex((item) => item._id === data.id);

            record.data.splice(index, 1);
            if (record.data.length === 0) {
              record.totalItems = 0;
              record.totalPages = 0;
            }

            this._bookmarks.next(record);
            return response;
          })
        )
      )
    );
  }
}
