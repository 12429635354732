import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Forum, ForumComment } from '../models';
import { UserService } from './user.service';

export interface ForumApiResponse {
  data: Forum[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

export interface ForumCommentsApiResponse {
  data: ForumComment[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class ForumService {
  private generateApiUrl = (path: string) => environment.apiURL + 'forum/' + path;

  public _forum: BehaviorSubject<Forum> = new BehaviorSubject<Forum>(null);
  private _allTopics: BehaviorSubject<ForumApiResponse | null> = new BehaviorSubject(null);
  private _forumComments: BehaviorSubject<ForumCommentsApiResponse | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get forum$(): Observable<Forum> {
    return this._forum.asObservable();
  }

  get allTopics$(): Observable<ForumApiResponse> {
    return this._allTopics.asObservable();
  }

  get forumComments$(): Observable<ForumCommentsApiResponse> {
    return this._forumComments.asObservable();
  }

  get(id: string): Observable<{ message: string; forum: Forum }> {
    return this._httpClient.get<{ message: string; forum: Forum }>(this.generateApiUrl(`single/${id}`)).pipe(
      tap((response) => {
        this._forum.next(response.forum);
      })
    );
  }

  create(data: any): Observable<{ message: string }> {
    return this._httpClient.post<{ message: string }>(this.generateApiUrl('create'), data);
  }

  getAll(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    topic = ''
  ): Observable<ForumApiResponse> {
    return this._httpClient
      .get<ForumApiResponse>(this.generateApiUrl('getAll'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          topic,
        },
      })
      .pipe(
        tap((response) => {
          this._allTopics.next(response);
        })
      );
  }

  update(data: any): Observable<{ message: string; forum: Forum }> {
    return this.allTopics$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient.put<{ message: string; forum: Forum }>(this.generateApiUrl('update'), data).pipe(
          map((response) => {
            // Find the index of the updated topic
            const index = records.data.findIndex((item) => item._id === data.forumId);

            // Update the topic
            records.data[index] = response.forum;

            // Update the state
            this._allTopics.next(records);
            this._forum.next(response.forum);

            return response;
          })
        )
      )
    );
  }

  public delete(topicId: string) {
    return this.allTopics$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl('del'), { body: { topicId } }).pipe(
          map((response) => {
            const index = records.data.findIndex((item) => item._id === topicId);

            records.data.splice(index, 1);

            // Update the state
            this._allTopics.next(records);
            return response;
          })
        )
      )
    );
  }

  getAllComments(
    post: string,
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string
  ): Observable<ForumCommentsApiResponse> {
    return this.forumComments$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .get<ForumCommentsApiResponse>(this.generateApiUrl('comments'), {
            params: {
              pageNo,
              size,
              sortBy,
              sortDirection,
              post,
            },
          })
          .pipe(
            map((response) => {
              if (records) {
                response.data = records.data.concat(response.data);
              }
              this._forumComments.next(response);
              return response;
            })
          )
      )
    );
  }

  addComment(data: { post: string; comment: string }): Observable<{ message: string; comment: ForumComment }> {
    return this.forumComments$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient.post<{ message: string; comment: ForumComment }>(this.generateApiUrl('addComment'), data).pipe(
          map((response) => {
            if (records) {
              records.data.unshift(response.comment);
            } else {
              {
                records = { data: [response.comment], morePages: false, page: 1, totalItems: 1, totalPages: 1 };
              }
            }

            this._forumComments.next(records);
            return response;
          })
        )
      )
    );
  }

  deleteComment(data: { commentId: string }): Observable<{ message: string }> {
    return this.forumComments$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl('delComment'), { body: data }).pipe(
          map((response) => {
            const index = records.data.findIndex((item) => item._id === data.commentId);

            records.data.splice(index, 1);

            this._forumComments.next(records);
            return response;
          })
        )
      )
    );
  }

  resetComments() {
    this._forumComments.next(null);
  }
}
