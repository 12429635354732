import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { OrdinalPipe } from '../pipes/ordinal.pipe';

@Component({
  selector: 'trophy-badge',
  templateUrl: './trophy-badge.component.html',
  styleUrls: ['./trophy-badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrophyBadgeComponent implements OnInit {
  @Input() badgeNumber: number = this.getRandomeBadge();
  @Input() badgeText: any = this.badgeNumber;

  constructor(private _oridinalPipe: OrdinalPipe) {}

  ngOnInit(): void {}

  getRandomeBadge(): number {
    const badges = [1, 2, 3, 4, 5];
    return badges[Math.floor(Math.random() * badges.length)];
  }

  getTooltipText() {
    return (
      'Hurray! You are ' +
      this._oridinalPipe.transform(this.badgeText) +
      ' registered user of Lamienins. We are excitied to have you in our platform.'
    );
  }
}
