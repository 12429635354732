import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Booking } from '../models/booking.model';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TourBookingService {
  private generateApiUrl = (path: string) => environment.apiURL + 'bookings/' + path;

  _myBookings: BehaviorSubject<any | null> = new BehaviorSubject(null);
  _toursSold: BehaviorSubject<any | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  get myBookings$(): Observable<any> {
    return this._myBookings.asObservable();
  }

  get toursSold$(): Observable<any> {
    return this._toursSold.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  create(data: {
    tourID: string;
    locationID: string;
    totalPrice: number;
    numberOfTickets: number;
    userInfo: any;
  }): Observable<{
    message: string;
    booking: Booking;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    client_secret: string;
  }> {
    return this._httpClient.post<{
      message: string;
      booking: Booking;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_secret: string;
    }>(this.generateApiUrl('create'), data);
  }

  updateStatus(data: { status: 'succeeded' | 'failed'; orderId: string }): Observable<{ message: string }> {
    return this._httpClient.post<{ message: string }>(this.generateApiUrl('updateBookingStatus'), data);
  }

  getBooking(orderID: string): Observable<Booking> {
    return this._httpClient.get<Booking>(this.generateApiUrl(`details/${orderID}`));
  }

  getBookings(pageNo: number, size: number, sortBy: string, sortDirection: string): Observable<any> {
    return this._httpClient
      .get<any>(this.generateApiUrl('getBookings'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          isSeller: true,
        },
      })
      .pipe(
        tap((response) => {
          this._toursSold.next(response);
          return response;
        })
      );
  }

  myBookings(pageNo: number, size: number, sortBy: string, sortDirection: string): Observable<any> {
    return this.myBookings$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .get<any>(this.generateApiUrl('getBookings'), {
            params: {
              pageNo,
              size,
              sortBy,
              sortDirection,
              isSeller: false,
            },
          })
          .pipe(
            tap((response) => {
              if (records) {
                response.data = records.data.concat(response.data);
              }
              this._myBookings.next(response);
              return response;
            })
          )
      )
    );
  }

  public cancel(data: {
    bookingId: string;
    cancel: boolean;
  }): Observable<{ message: string; booking?: any; refundAmt?: number }> {
    return this.myBookings$.pipe(
      take(1),
      switchMap((bookings) =>
        this._httpClient
          .post<{ message: string; booking?: any; refundAmt?: number }>(this.generateApiUrl('cancelBooking'), data)
          .pipe(
            tap((response) => {
              const index = bookings.data.findIndex((item) => item._id === data.bookingId);

              if (response.booking) {
                bookings.data[index] = response.booking;
                this._myBookings.next(bookings);
              }
            })
          )
      )
    );
  }
}
