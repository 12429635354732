import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Blog } from '../models';

export interface BlogsApiResponse {
  data: Blog[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class BlogsService {
  private generateApiUrl = (path: string) => environment.apiURL + 'blog/' + path;

  public _blog: BehaviorSubject<Blog> = new BehaviorSubject<Blog>(null);
  private _blogs: BehaviorSubject<BlogsApiResponse | null> = new BehaviorSubject(null);
  public _isSubmittingForm: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get blog$(): Observable<Blog> {
    return this._blog.asObservable();
  }

  get blogs$(): Observable<BlogsApiResponse> {
    return this._blogs.asObservable();
  }

  getBlog(id: string): Observable<{ message: string; blog: Blog }> {
    return this._httpClient.get<{ message: string; blog: Blog }>(this.generateApiUrl(`single/${id}`)).pipe(
      tap((response) => {
        this._blog.next(response.blog);
      })
    );
  }

  create(data: any): Observable<{ message: string; blog: Blog }> {
    return this._httpClient.post<{ message: string; blog: Blog }>(this.generateApiUrl('create'), data);
  }

  getAllBlog(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    title = '',
    category = ''
  ): Observable<BlogsApiResponse> {
    return this.blogs$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .get<BlogsApiResponse>(this.generateApiUrl('getAll'), {
            params: {
              pageNo,
              size,
              sortBy,
              sortDirection,
              title,
              category,
            },
          })
          .pipe(
            tap((response) => {
              if (records) {
                response.data = records.data.concat(response.data);
              }
              this._blogs.next(response);
              return response;
            })
          )
      )
    );
  }

  update(data: any): Observable<{ message: string; blog: Blog }> {
    return this._httpClient.put<{ message: string; blog: Blog }>(this.generateApiUrl('update'), data).pipe(
      tap((response) => {
        this._blog.next(response.blog);
      })
    );
  }

  public delete(data: { blogId: string }) {
    return this.blogs$.pipe(
      take(1),
      switchMap((blogs) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl('del'), { body: data }).pipe(
          map((response) => {
            const index = blogs.data.findIndex((item) => item._id === data.blogId);

            blogs.data.splice(index, 1);

            this._blogs.next(blogs);
            return response;
          })
        )
      )
    );
  }
}
