import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageRefService } from './local-storage-ref.service';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private _localStorage: Storage;

  private _recentlyLoggedInPageIds = new BehaviorSubject<any[]>(null);
  _recentlyLoggedInPageIds$ = this._recentlyLoggedInPageIds.asObservable();

  constructor(_localStorageRefService: LocalStorageRefService) {
    this._localStorage = _localStorageRefService.localStorage;
  }

  set(key: string, data: any): void {
    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }
    this._localStorage.setItem(key, data);
  }

  get(key: string): any {
    let data = this._localStorage.getItem(key);
    if (data) {
      data = JSON.parse(data);
    }
    return data;
  }

  remove(key: string) {
    this._localStorage.removeItem(key);
  }

  clearAllLocalStorage(): void {
    this._localStorage.clear();
  }

  savePageId(pageId: string) {
    let pageIds = this.get('accounts');
    if (pageIds && pageIds.length > 0) {
      if (!pageIds.some((id) => id === pageId)) {
        pageIds.push(pageId);
      }
    } else {
      pageIds = [pageId];
    }
    this._recentlyLoggedInPageIds.next(pageIds);
    this.set('accounts', pageIds);
  }

  updatePageIdObserver() {
    this._recentlyLoggedInPageIds.next(this.get('accounts'));
  }
}
