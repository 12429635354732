import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { CookieService } from 'ngx-cookie-service';
import { appInitializer, ErrorInterceptor, JwtInterceptor } from './helpers';
import {
  AuthService,
  BlogsService,
  BookmarkService,
  ChatService,
  CountryStatesCitiesService,
  CustomSnackbarService,
  DonationService,
  ForumService,
  LamieninsService,
  MusicService,
  NavigatorShareService,
  NewsService,
  OverlayService,
  PageService,
  PlaylistService,
  ProductOrderService,
  ProductService,
  SocketService,
  StripeAPiService,
  SupportService,
  TasksService,
  TourService,
  UserService,
} from './services';
import { LocalStorageRefService } from './services/local-storage-ref.service';

@NgModule({
  imports: [IconsModule, TranslocoCoreModule, MatSnackBarModule],
  providers: [
    AuthService,
    UserService,
    PageService,
    CookieService,
    ProductService,
    TourService,
    CustomSnackbarService,
    LamieninsService,
    NewsService,
    MusicService,
    StripeAPiService,
    ProductOrderService,
    DonationService,
    SocketService,
    ChatService,
    TasksService,
    LocalStorageRefService,
    ForumService,
    PlaylistService,
    BlogsService,
    BookmarkService,
    SupportService,
    NavigatorShareService,
    CountryStatesCitiesService,
    OverlayService,
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class CoreModule {
  /**
   * Constructor
   */
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    // Do not allow multiple injections
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import this module in the AppModule only.');
    }
  }
}
