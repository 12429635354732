import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Conversation, Message } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private generateApiUrl = (path: string) => environment.apiURL + 'chat/' + path;

  public _conversations: BehaviorSubject<Conversation[]> = new BehaviorSubject(null);
  public _messages: BehaviorSubject<Message[]> = new BehaviorSubject(null);
  public _conversation: BehaviorSubject<Conversation> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get conversations$(): Observable<Conversation[]> {
    return this._conversations.asObservable();
  }

  get messages$(): Observable<Message[]> {
    return this._messages.asObservable();
  }

  get conversation$(): Observable<Conversation> {
    return this._conversation.asObservable();
  }

  getConversations(name = ''): Observable<Conversation[]> {
    return this._httpClient
      .get<Conversation[]>(this.generateApiUrl('fetchConversations'), {
        params: {
          name,
        },
      })
      .pipe(
        tap((response) => {
          this._conversations.next(response);
        })
      );
  }

  getConversation(conversationID: string): Observable<Conversation> {
    return this._httpClient.get<Conversation>(this.generateApiUrl('fetchConversations/' + conversationID)).pipe(
      tap((response) => {
        this._conversation.next(response);
      })
    );
  }

  getMessages(conversationID: string): Observable<Message[]> {
    return this._httpClient.get<Message[]>(this.generateApiUrl('fetchMessages/' + conversationID)).pipe(
      tap((response) => {
        this._messages.next(response);
      })
    );
  }

  /**
   * Reset the selected chat
   */
  resetChat(): void {
    this._conversation.next(null);
  }
}
