import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementChangeEvent, StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';

@Component({
  selector: 'business-stripe-card',
  templateUrl: './stripe-card.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeCardDetailsComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  /* eslint-disable quote-props */
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };
  /* eslint-enable quote-props */

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(private stripeService: StripeService) {}

  ngOnInit(): void {}

  onChange(ev: StripeCardElementChangeEvent) {
    const displayError = document.getElementById('card-errors');
    if (ev.error) {
      displayError.textContent = ev.error.message;
    } else {
      displayError.textContent = '';
    }
  }
}
