<!-- Bar search -->
<ng-container *ngIf="appearance === 'bar'">
  <button mat-icon-button *ngIf="!opened" (click)="open()">
    <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
  </button>
  <div class="absolute inset-0 flex items-center flex-shrink-0 z-99 bg-card" *ngIf="opened" @slideInTop @slideOutTop>
    <mat-icon class="absolute ml-6 sm:ml-8" [svgIcon]="'heroicons_outline:search'"></mat-icon>
    <form [formGroup]="searchForm" class="w-full h-full" (ngSubmit)="onSearch()">
      <input
        id="search"
        matInput
        [formControlName]="'search'"
        [placeholder]="'Search for a business'"
        class="w-full h-full px-16 sm:px-18"
        (keydown)="onKeydown($event)"
        #barSearchInput
      />
    </form>

    <button class="absolute top-1/2 right-5 sm:right-7 flex-shrink-0 w-10 h-10 -mt-5" mat-icon-button (click)="close()">
      <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>
</ng-container>

<!-- Basic search -->
<ng-container *ngIf="appearance === 'basic'">
  <div class="w-full sm:min-w-100">
    <form [formGroup]="searchForm" class="w-full h-full" (ngSubmit)="onSearch()">
      <mat-form-field class="fuse-mat-no-subscript w-full">
        <mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
        <input
          matInput
          [formControlName]="'search'"
          [placeholder]="'Search for a business'"
          (keydown)="onKeydown($event)"
        />
        <mat-error *ngIf="searchForm.get('search').hasError('required')"> Search keyword is required </mat-error>
        <mat-error *ngIf="searchForm.get('search').hasError('min')"> Please enter min 2 keyword </mat-error>
      </mat-form-field>
    </form>
  </div>
</ng-container>
