import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { ModalGalleryService, Image } from '@ks89/angular-modal-gallery';
import { Page } from 'app/core/models';
import { LamieninsService, PageService } from 'app/core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewerComponent implements OnInit, OnDestroy {
  images: any[] = [];
  @Input() urlKey = '';
  @Input() usePagination: boolean = false;
  @Output() deleteImageEvent: EventEmitter<any> = new EventEmitter();
  @Output() scrolled: EventEmitter<any> = new EventEmitter();

  modalImages: Image[] = [];
  page: Page;

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private _modalGalleryService: ModalGalleryService,
    private _lamieninsService: LamieninsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _pageService: PageService
  ) {}

  ngOnInit(): void {
    this._lamieninsService.photos$.pipe(takeUntil(this._unsubscribeAll)).subscribe((finalPhotos) => {
      this.images = finalPhotos;
      if (this.images && this.images.length > 0) {
        this.modalImages = this.images.map((img, index) => new Image(img._id || index, { img: img[this.urlKey] }));
      }

      this._changeDetectorRef.detectChanges();
    });

    this._pageService.page$.pipe(takeUntil(this._unsubscribeAll)).subscribe((page) => (this.page = page));
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  onShow(id: number, index: number, images: Image[] = this.modalImages): void {
    this._modalGalleryService.open({
      id,
      images,
      currentImage: images[index],
    });
  }

  public deleteImage(imageid: string) {
    this.deleteImageEvent.emit(imageid);
  }

  onScroll() {
    this.scrolled.emit();
  }
}
