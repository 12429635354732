<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <fuse-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false"
    *ngIf="user"
    style="background: linear-gradient(to top, #a91823 0%, #ef4444 100%)"
  >
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex items-center h-20 pt-2 px-8 cursor-pointer" [routerLink]="['']">
        <img class="w-100 bg-primary rounded-lg shadow-2xl" src="assets/images/logo/white-full.svg" alt="Logo image" />
      </div>
    </ng-container>
  </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
  <!-- Header -->
  <div
    class="
      relative
      flex flex-0
      items-center
      w-full
      h-16
      sm:h-20
      px-4
      md:px-6
      z-49
      shadow
      dark:shadow-none dark:border-b
      bg-card
      dark:bg-transparent
      print:hidden
    "
  >
    <ng-container *ngIf="!isScreenSmall">
      <!-- Logo -->
      <div class="flex items-center mx-2 lg:mr-8">
        <div class="hidden lg:flex cursor-pointer" [routerLink]="['']">
          <img class="w-50 bg-primary rounded-lg shadow-2xl" src="assets/images/logo/white-full.svg" alt="logo-text" />
        </div>
        <img class="flex lg:hidden w-8" src="assets/images/logo/logo.svg" alt="logo icon" />
      </div>
      <!-- Horizontal navigation -->
      <fuse-horizontal-navigation
        *ngIf="user"
        class="mr-2"
        [name]="'mainNavigation'"
        [navigation]="navigation.horizontal"
      >
      </fuse-horizontal-navigation>
    </ng-container>
    <!-- Navigation toggle button -->
    <ng-container *ngIf="isScreenSmall && user">
      <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
      </button>
    </ng-container>
    <!-- Components -->
    <div class="items-center pl-2 ml-72 hidden sm:flex" *ngIf="!isScreenSmall">
      <search [appearance]="'basic'"></search>
    </div>
    <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2" *ngIf="user">
      <!-- <languages></languages> -->
      <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
      <search [appearance]="'bar'" *ngIf="isScreenSmall"></search>
      <!-- <shortcuts></shortcuts> -->
      <!-- <messages></messages> -->
      <!-- <notifications></notifications> -->
      <user></user>
    </div>
    <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2" *ngIf="!user">
      <button class="fuse-mat-button-rounded" mat-flat-button [color]="'primary'" [routerLink]="['/sign-up']">
        <mat-icon class="icon-size-5" [svgIcon]="'mat_outline:app_registration'"></mat-icon>
        <span class="ml-2">Sing Up</span>
      </button>
      <button class="fuse-mat-button-rounded" mat-flat-button [color]="'primary'" [routerLink]="['/sign-in']">
        <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:login'"></mat-icon>
        <span class="ml-2">Log In</span>
      </button>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto w-full">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <!-- <div
        class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div> -->
</div>
