import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertType } from '@fuse/components/alert';
import { Country, CountryStatesCitiesService, CustomSnackbarService, State, UserService } from 'app/core/services';
import { Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-address-dialog',
  templateUrl: './add-edit-address-dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class AddEditAddressDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  data: any;
  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };
  showAlert: boolean = false;
  @ViewChild('dataForm') dataForm: NgForm;
  countries$: Observable<Country[]>;
  states$: Observable<State[]>;
  isLoading = false;

  /** Subject that emits when the component has been destroyed. */
  private _unsubscribeAll: Subject<void> = new Subject<void>();

  constructor(
    fb: FormBuilder,
    private dialogRef: MatDialogRef<AddEditAddressDialogComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _userService: UserService,
    private _customSnackbarService: CustomSnackbarService,
    private _countryStatesCitiesService: CountryStatesCitiesService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.data = data;
    this.form = fb.group({
      addressLine: ['', [Validators.required]],
      name: ['', [Validators.required]],
      mobile: [null, [Validators.required, Validators.pattern(/^((\\+91-?)|0)?[\d]{10}$/)]],
      pin: ['', [Validators.required, Validators.pattern(/^\d{5}$/)]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['', [Validators.required]],
      landmark: '',
    });
  }

  ngOnInit() {
    this.countries$ = this._countryStatesCitiesService.countries$;
    this.states$ = this._countryStatesCitiesService.states$;

    this.getCountries();

    if (this.data.type === 'Edit') {
      // Fill the form
      this.form.patchValue(this.data);

      // Mark for check
      this._changeDetectorRef.markForCheck();
    }

    this.form
      .get('country')
      .valueChanges.pipe(
        takeUntil(this._unsubscribeAll),
        filter((val) => val),
        tap(() => (this.isLoading = true)),
        switchMap((newVal) => this._countryStatesCitiesService.getStates(newVal))
      )
      .subscribe(
        (value) => {
          // reset the state if the selected state value is not there in the newStates array
          const stateFrmCtrl = this.form.get('state');
          if (!value.some((state) => state.isoCode === stateFrmCtrl.value)) {
            stateFrmCtrl.reset();
          }
          this.isLoading = false;
          this._changeDetectorRef.markForCheck();
        },
        (err) => this._customSnackbarService.open(err, 'error', 'Ok')
      );
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  private getCountries() {
    this.isLoading = true;
    this._countryStatesCitiesService
      .getCountries()
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe(
        () => {
          const countryCtrl = this.form.controls['country'];
          countryCtrl.disable();
          countryCtrl.setValue('US');
          this._changeDetectorRef.markForCheck();
        },
        (err) => {
          this._customSnackbarService.open(err, 'error', 'Ok');
        }
      );
  }

  save() {
    // Return if the form is invalid
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    } else if (Object.keys(this.getDirtyValues(this.form)).length === 0) {
      return this._customSnackbarService.open('No value changed to update', 'error', 'Ok');
    }

    // Disable the form
    this.form.disable();

    // Hide the alert
    this.showAlert = false;

    if (this.data.type === 'Add') {
      this._userService.addNewAddress(this.form.value).subscribe(
        (response) => {
          this.form.enable();
          this._customSnackbarService.open(response.message, 'info', 'ok');
          this.close(true);
        },
        (error) => {
          this.form.enable();
          this._customSnackbarService.open(error, 'error', 'ok');
        }
      );
    } else if (this.data.type === 'Edit') {
      this._userService.editAddress(this.data._id, this.form.value).subscribe(
        (response) => {
          this.form.enable({ emitEvent: false });
          this._customSnackbarService.open(response.message, 'info', 'ok');
          this.close(true);
        },
        (error) => {
          this.form.enable();
          this._customSnackbarService.open(error, 'error', 'ok');
        }
      );
    }
  }

  close(value = false) {
    this.dialogRef.close(value);
  }

  getDirtyValues(form: any) {
    const dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
        } else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

    return dirtyValues;
  }
}
