import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Music } from '../models';
import { ApiResponse, MusicService } from '.';

@Injectable({
  providedIn: 'root',
})
export class PlaylistService {
  private generateApiUrl = (path: string) => environment.apiURL + 'playlist/' + path;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _musciService: MusicService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  add(musicId: string, fromPlaylistPage = false): Observable<{ message: string; music: Music }> {
    return this._musciService.allMusic$.pipe(
      take(1),
      switchMap((music) =>
        this._httpClient.post<{ message: string; music: Music }>(this.generateApiUrl('add'), { musicId }).pipe(
          map((response) => {
            const index = music.data.findIndex((item) => item._id === musicId);
            if (fromPlaylistPage && index === -1) {
              music.data.unshift(response.music);
            } else {
              music.data[index].isSavedInPlaylist = true;
            }
            if (this._musciService.currentMusic) {
              this._musciService.currentMusic.music.isSavedInPlaylist = true;
            }
            this._musciService._allMusic.next(music);
            return response;
          })
        )
      )
    );
  }

  getAllMusic(pageNo: number, size: number, sortBy: string, sortDirection: string, name = ''): Observable<ApiResponse> {
    return this._httpClient
      .get<ApiResponse>(this.generateApiUrl('getAll'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          name,
        },
      })
      .pipe(
        tap((response) => {
          this._musciService._allMusic.next(response);
        })
      );
  }

  public remove(musicId: string, fromPlaylistPage = false) {
    return this._musciService.allMusic$.pipe(
      take(1),
      switchMap((music) =>
        this._httpClient.post<{ message: string }>(this.generateApiUrl('remove'), { musicId }).pipe(
          map((response) => {
            const index = music.data.findIndex((item) => item._id === musicId);
            if (fromPlaylistPage) {
              music.data.splice(index, 1);
            } else {
              music.data[index].isSavedInPlaylist = false;
            }

            if (this._musciService.currentMusic) {
              this._musciService.currentMusic.music.isSavedInPlaylist = false;
            }

            this._musciService._allMusic.next(music);
            return response;
          })
        )
      )
    );
  }
}
