<div
  class="
    relative
    flex flex-auto
    items-center
    justify-center
    h-full
    p-16
    lg:px-28
    overflow-hidden
    bg-primary
    dark:border-l
  "
>
  <!-- Background - @formatter:off -->
  <!-- Rings -->
  <svg
    class="absolute inset-0 pointer-events-none"
    viewBox="0 0 960 540"
    width="100%"
    height="100%"
    preserveAspectRatio="xMidYMax slice"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g class="text-primary-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
      <circle r="234" cx="196" cy="23"></circle>
      <circle r="234" cx="790" cy="491"></circle>
    </g>
  </svg>
  <!-- Dots -->
  <svg class="absolute -top-16 -right-16 text-primary-700" viewBox="0 0 220 192" width="220" height="192" fill="none">
    <defs>
      <pattern
        id="837c3e70-6c3a-44e6-8854-cc48c737b659"
        x="0"
        y="0"
        width="20"
        height="20"
        patternUnits="userSpaceOnUse"
      >
        <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
      </pattern>
    </defs>
    <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
  </svg>
  <div class="z-10 relative w-full max-w-2xl">
    <div class="text-7xl font-bold leading-none text-gray-100">
      <div>Welcome to</div>
      <div>Lamienins</div>
    </div>
    <div class="mt-6 text-lg tracking-tight leading-6 text-gray-200">
      Lamienins is a platform where people can connect with different musicians, buy their merchandise and do many more
      cool stuffs.
    </div>
  </div>
</div>
