<div
  class="flex flex-col sm:flex-row flex-wrap gap-4"
  infiniteScroll
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="50"
  (scrolled)="onScroll()"
>
  <div
    class="
      rounded-lg
      shadow-lg
      sm:shadow-md
      hover:shadow-xl
      cursor-pointer
      transform
      transition-shadow
      ease-in-out
      duration-150
      bg-card
      p-2
    "
    *ngFor="let img of images; let i = index; trackBy: trackByFn"
  >
    <!-- Dismiss button -->
    <ng-container *ngIf="page && page.isOwner">
      <div class="absolute top-0 right-0 pt-1 pr-1">
        <button mat-icon-button (click)="deleteImage(img._id)">
          <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </button>
      </div>
    </ng-container>

    <img
      [defaultImage]="'assets/images/spinner.svg'"
      [errorImage]="'assets/images/no-image-icon.png'"
      [lazyLoad]="img[urlKey]"
      offset="100"
      class="object-cover w-full sm:w-50 h-72 sm:h-50"
      alt="image"
      (click)="onShow(203, i)"
    />
  </div>
</div>
