import { Component, OnDestroy, OnInit } from '@angular/core';
import { SPINNER } from 'ngx-ui-loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from './core/services';
import { SocketService } from './core/services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public spinner = SPINNER;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(private _socketService: SocketService, private _userService: UserService) {}

  ngOnInit() {
    this._userService.userSubject$.pipe(takeUntil(this._unsubscribeAll)).subscribe((userObj) => {
      if (userObj && userObj.token) {
        this._socketService.initSocket(userObj.token);
      }
    });
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
