import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Page, PagePhoto } from '../models';
import { LocalStorageService } from './local-storage.service';

export interface PagePhotosApiResponse {
  data: PagePhoto[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

export interface PagesApiResponse {
  data: Page[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

export interface ICards {
  selectedCards: any[];
  allCards?: any[];
}
@Injectable({
  providedIn: 'root',
})
export class PageService {
  private generateApiUrl = (path: string) => environment.apiURL + 'page/' + path;

  private _page: BehaviorSubject<Page> = new BehaviorSubject<Page>(null);
  private pageSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _pagePhotos: BehaviorSubject<PagePhotosApiResponse | null> = new BehaviorSubject(null);
  private _pages: BehaviorSubject<Page[]> = new BehaviorSubject<Page[]>(null);
  private _cards: BehaviorSubject<ICards> = new BehaviorSubject<ICards>(null);
  _searchPagesResult: BehaviorSubject<PagesApiResponse | null> = new BehaviorSubject<PagesApiResponse>(null);
  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _localStorageService: LocalStorageService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  set page(value: any) {
    this.pageSubject.next(value);
  }
  get page$(): Observable<Page> {
    return this._page.asObservable();
  }

  get pageAsObj(): Page {
    return this.pageSubject.value?.page;
  }

  get pagePhotos$(): Observable<PagePhotosApiResponse> {
    return this._pagePhotos.asObservable();
  }

  get pages$(): Observable<Page[]> {
    return this._pages.asObservable();
  }

  get searchResult$(): Observable<PagesApiResponse> {
    return this._searchPagesResult.asObservable();
  }

  get cards$(): Observable<ICards> {
    return this._cards.asObservable();
  }

  getPage(id: string): Observable<Page> {
    return this._httpClient.get<Page>(environment.apiURL + 'page/single/' + id).pipe(
      tap((response) => {
        this._page.next(response);
      })
    );
  }

  update(data: any): Observable<{ page: Page; message: string }> {
    return this._httpClient.post<{ page: Page; message: string }>(environment.apiURL + 'page/update', data).pipe(
      tap((updateData) => {
        this._page.next(updateData.page);

        return updateData;
      })
    );
  }

  updateImage(data: {
    pageid: string;
    base64Logo?: string;
    base64Cover?: string;
  }): Observable<{ message: string; page: Page }> {
    return this._httpClient.post<{ page: Page; message: string }>(environment.apiURL + 'page/updateImage', data).pipe(
      tap((response) => {
        this._page.next(response.page);
      })
    );
  }

  getAllPhotos(
    pageid: string,
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string
  ): Observable<PagePhotosApiResponse> {
    return this.pagePhotos$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .get<PagePhotosApiResponse>(this.generateApiUrl('fetchPhotos'), {
            params: {
              pageNo,
              size,
              sortBy,
              sortDirection,
              pageid,
            },
          })
          .pipe(
            map((response) => {
              if (records) {
                // Merge Two Arrays of Objects, Only If Not Duplicate (Based on Specified Object Key) Taken from: https://stackoverflow.com/a/54134237/8062849
                const ids = new Set(records.data.map((d) => d._id));
                response.data = [...records.data, ...response.data.filter((d) => !ids.has(d._id))];
              }
              this._pagePhotos.next(response);
              return response;
            })
          )
      )
    );
  }

  uploadPhotos(pageid: string, data: FormData): Observable<{ message: string; photos: PagePhoto[] }> {
    return this.pagePhotos$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .post<{ message: string; photos: PagePhoto[] }>(this.generateApiUrl(`uploadPhoto?pageid=${pageid}`), data)
          .pipe(
            map((response) => {
              records.data = response.photos.concat(records.data);
              this._pagePhotos.next(records);
              return response;
            })
          )
      )
    );
  }

  deletePhoto(data: { pageid: string; photoId: string }): Observable<{ message: string }> {
    return this.pagePhotos$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient.post<{ message: string }>(this.generateApiUrl('deletePhoto'), data).pipe(
          map((response) => {
            const index = records.data.findIndex((item) => item._id === data.photoId);

            records.data.splice(index, 1);

            this._pagePhotos.next(records);
            return response;
          })
        )
      )
    );
  }

  getRecentlyLoggedInPages() {
    return this._localStorageService._recentlyLoggedInPageIds$.pipe(
      take(1),
      switchMap((pageids) => {
        if (!pageids) {
          this._pages.next([]);
          return [];
        }
        return this._httpClient.post<Page[]>(this.generateApiUrl('pages'), { pageids }).pipe(
          tap((response) => {
            this._pages.next(response);
            return response;
          })
        );
      })
    );
  }

  getAllPages(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    name = ''
  ): Observable<PagesApiResponse> {
    return this.searchResult$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .get<PagesApiResponse>(this.generateApiUrl('fetchpages'), {
            params: {
              pageNo,
              size,
              sortBy,
              sortDirection,
              name,
            },
          })
          .pipe(
            map((response) => {
              if (records) {
                response.data = records.data.concat(response.data);
              }
              this._searchPagesResult.next(response);
              return response;
            })
          )
      )
    );
  }

  follow(pageId: string): Observable<{ message: string; isFollowed: boolean }> {
    return this.page$.pipe(
      take(1),
      switchMap((page) =>
        this._httpClient
          .post<{ message: string; isFollowed: boolean }>(this.generateApiUrl('follow'), {
            pageId,
          })
          .pipe(
            map((response) => {
              if (page) {
                page.isFollowed = response.isFollowed;
              }
              this._page.next(page);
              return response;
            })
          )
      )
    );
  }

  unFollow(pageId: string): Observable<{ message: string; isFollowed: boolean }> {
    return this.page$.pipe(
      take(1),
      switchMap((page) =>
        this._httpClient
          .post<{ message: string; isFollowed: boolean }>(this.generateApiUrl('unfollow'), {
            pageId,
          })
          .pipe(
            map((response) => {
              if (page) {
                page.isFollowed = response.isFollowed;
              }
              this._page.next(page);
              return response;
            })
          )
      )
    );
  }

  clap(pageId: string): Observable<{ message: string; isClapped: boolean }> {
    return this.page$.pipe(
      take(1),
      switchMap((page) =>
        this._httpClient
          .post<{ message: string; isClapped: boolean }>(this.generateApiUrl('clap'), {
            pageId,
          })
          .pipe(
            map((response) => {
              if (page) {
                page.isClapped = response.isClapped;
              }
              this._page.next(page);
              return response;
            })
          )
      )
    );
  }

  removeClap(pageId: string): Observable<{ message: string; isClapped: boolean }> {
    return this.page$.pipe(
      take(1),
      switchMap((page) =>
        this._httpClient
          .post<{ message: string; isClapped: boolean }>(this.generateApiUrl('removeClap'), {
            pageId,
          })
          .pipe(
            map((response) => {
              if (page) {
                page.isClapped = response.isClapped;
              }
              this._page.next(page);
              return response;
            })
          )
      )
    );
  }

  getFollowSuggestion(): Observable<Page[]> {
    return this._httpClient.get<Page[]>(this.generateApiUrl('suggestions'));
  }

  getMyFollowing(): Observable<Page[]> {
    return this._httpClient.get<Page[]>(this.generateApiUrl('following'));
  }

  getCards(pageId: string): Observable<ICards> {
    return this._httpClient.get<ICards>(this.generateApiUrl('cards'), { params: { pageId } }).pipe(
      tap((response) => {
        this._cards.next(response);
      })
    );
  }

  updateCards(cardIds: string[], pageid: string): Observable<{ message: string }> {
    return this.cards$.pipe(
      take(1),
      switchMap((cards) =>
        this._httpClient.post<any>(this.generateApiUrl('updateCards'), { cardIds, pageid }).pipe(
          map((response) => {
            if (cards) {
              cards.selectedCards = cards.allCards.filter(
                (card) => card.isDefault || cardIds.some((id) => card._id === id)
              );
            }
            this._cards.next(cards);
            return response;
          })
        )
      )
    );
  }
}
