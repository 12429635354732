/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/home',
  },
  {
    id: 'business',
    title: 'Business',
    type: 'basic',
    icon: 'mat_outline:business',
    link: '/business',
  },
  {
    id: 'office',
    title: 'Office',
    type: 'collapsable',
    icon: 'heroicons_outline:office-building',
    link: '/office',
    children: [
      {
        id: 'office.home',
        title: 'Home',
        icon: 'heroicons_outline:home',
        type: 'basic',
        link: '/office',
        exactMatch: true,
      },
      {
        id: 'office.switchpages',
        title: 'Switch Page',
        type: 'basic',
        icon: 'heroicons_solid:switch-horizontal',
        link: '/office/switchpages',
      },
      {
        id: 'office.purchases',
        title: 'Purchases',
        type: 'basic',
        icon: 'heroicons_outline:shopping-cart',
        link: '/office/purchases',
      },
      {
        id: 'office.sales',
        title: 'Sales',
        type: 'basic',
        icon: 'mat_outline:sell',
        link: '/office/sales',
      },
      {
        id: 'office.forum',
        title: 'Forum',
        type: 'basic',
        icon: 'mat_outline:forum',
        link: '/office/forum',
      },
      {
        id: 'office.blog',
        title: 'Blog',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/office/blog',
      },
      {
        id: 'office.todolist',
        title: 'Todo-List',
        type: 'basic',
        icon: 'heroicons_outline:check-circle',
        link: '/office/todolist',
      },
      {
        id: 'office.messages',
        title: 'Messages',
        type: 'basic',
        icon: 'heroicons_outline:chat-alt',
        link: '/office/messages',
      },
      {
        id: 'office.playlist',
        title: 'Playlist',
        type: 'basic',
        icon: 'mat_outline:playlist_add_check',
        link: '/office/playlist',
      },
      {
        id: 'office.support',
        title: 'Support',
        type: 'basic',
        icon: 'mat_outline:support',
        link: '/office/support',
      },
      {
        id: 'office.bookmarks',
        title: 'Bookmarks',
        type: 'basic',
        icon: 'mat_outline:bookmarks',
        link: '/office/bookmarks',
      },
    ],
  },
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'example',
    title: 'Example',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/example',
  },
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/home',
  },
  {
    id: 'business',
    title: 'Business',
    type: 'basic',
    icon: 'mat_outline:business',
    link: '/business',
  },
  {
    id: 'office',
    title: 'Office',
    type: 'collapsable',
    icon: 'heroicons_outline:office-building',
    link: '/office',
    children: [],
  },
];
