import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomSnackbarService, NavigatorShareService } from 'app/core/services';

@Component({
  selector: 'share-invite',
  templateUrl: './share-invite.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareInviteComponent implements OnInit {
  @Input() buttonStyle: 'stroked' | 'flat' = 'flat';
  @Input() buttonText = 'Share';
  @Input() shareCurrentPage: boolean = false;
  currentPageFullUrl = '';
  currentOriginUrl = '';

  constructor(
    public _navigatorShareService: NavigatorShareService,
    private _customSnackbarService: CustomSnackbarService,
    private _router: Router,
    private _title: Title
  ) {}

  ngOnInit(): void {
    this.currentPageFullUrl = this._router['location']._platformLocation.location.href;
    this.currentOriginUrl = this._router['location']._platformLocation.location.origin;
  }

  webApiShare() {
    let shareDetails: any = {};
    if (this.shareCurrentPage) {
      shareDetails = {
        title: this._title.getTitle(),
        text: 'Hey, Please check out my business on Lamienins',
        url: this.currentPageFullUrl,
      };
    } else {
      // Generic share
      shareDetails = {
        title: 'Lamienins',
        text: 'Hey, Please check out this cool website',
        url: this.currentOriginUrl,
      };
    }
    this._navigatorShareService
      .share(shareDetails)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        // this._customSnackbarService.open(error.error, 'error', 'ok');
        console.log(error);
      });
  }
}
