<fuse-card class="flex flex-col items-center w-full sm:w-1/2 p-5 shadow-lg sm:shadow-md">
  <div class="flex flex-col flex-auto w-full">
    <div class="mt-3 flex flex-col">
      <mat-form-field class="w-full">
        <div>
          <mat-toolbar>
            <input matInput [value]="selectedFileNames.length ? selectedFileNames : 'Select Images'" readonly />
          </mat-toolbar>

          <input
            type="file"
            id="fileInput"
            name="fileInput"
            accept="image/*"
            multiple
            (change)="selectFiles($event)"
            class="w-full"
            [disabled]="isUploadingImage"
          />
        </div>
      </mat-form-field>

      <p class="flex flex-col sm:flex-row flex-wrap gap-4">
        <img
          *ngFor="let img of previews; trackBy: trackByFn"
          [src]="img"
          class="
            object-cover
            w-full
            sm:w-50
            h-72
            sm:h-50
            bg-card
            p-2
            rounded-lg
            shadow-lg
            sm:shadow-md
            hover:shadow-xl
            cursor-pointer
            transform
            transition-shadow
            ease-in-out
            duration-150
          "
          alt="image"
        />
      </p>
    </div>
    <div class="flex items-center mt-3">
      <button
        class="px-3 mr-1"
        type="button"
        mat-flat-button
        [color]="'primary'"
        matTooltip="Clicking on this will upload the images"
        [disabled]="!selectedFiles || isUploadingImage"
        (click)="uploadFiles()"
      >
        <mat-icon [svgIcon]="'heroicons_outline:plus'" *ngIf="!isUploadingImage"></mat-icon>
        <span class="ml-2 mr-1" *ngIf="!isUploadingImage">Upload</span>
        <mat-progress-spinner *ngIf="isUploadingImage" [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
      </button>
    </div>
  </div>
</fuse-card>
