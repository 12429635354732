import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Tour } from '../models';
import { UserService } from './user.service';

export interface ToursApiResponse {
  data: Tour[];
  totalPages: number;
  totalItems: number;
}

@Injectable({
  providedIn: 'root',
})
export class TourService {
  private generateApiUrl = (path: string) => environment.apiURL + 'tours/' + path;

  public _tour: BehaviorSubject<Tour> = new BehaviorSubject<Tour>(null);
  private _tours: BehaviorSubject<ToursApiResponse | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get tour$(): Observable<Tour> {
    return this._tour.asObservable();
  }

  get tours$(): Observable<ToursApiResponse> {
    return this._tours.asObservable();
  }

  getTour(id: string): Observable<{ tour: Tour; applicationFee: string }> {
    return this._httpClient.get<{ tour: Tour; applicationFee: string }>(this.generateApiUrl(`single/${id}`)).pipe(
      tap((response) => {
        this._tour.next(response.tour);
      })
    );
  }

  create(data: any): Observable<{ message: string; tour: Tour }> {
    return this._httpClient.post<{ message: string; tour: Tour }>(this.generateApiUrl('add'), data);
  }

  getAllTours(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    pageid = '',
    name = ''
  ): Observable<ToursApiResponse> {
    return this._httpClient
      .get<ToursApiResponse>(this.generateApiUrl('fetchall'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          pageid: pageid || this._userService.userObj.page,
          name,
        },
      })
      .pipe(
        tap((response) => {
          this._tours.next(response);
        })
      );
  }

  updateTourStatus(data: any): Observable<{ message: string; tour: Tour }> {
    return this._httpClient.post<{ message: string; tour: Tour }>(this.generateApiUrl('activeInactive'), data).pipe(
      tap((response) => {
        this._tour.next(response.tour);
      })
    );
  }

  updateTour(data: any, id: string): Observable<{ message: string; tour: Tour }> {
    return this._httpClient.put<{ message: string; tour: Tour }>(this.generateApiUrl(`update/${id}`), data).pipe(
      tap((response) => {
        this._tour.next(response.tour);
      })
    );
  }

  updateImage(data: { tourid: string; base64TourImage: string }): Observable<{ message: string; tour: Tour }> {
    return this._httpClient.post<{ tour: Tour; message: string }>(this.generateApiUrl('uploadimage'), data).pipe(
      tap((response) => {
        this._tour.next(response.tour);
      })
    );
  }

  public deleteTour(id: string) {
    return this.tours$.pipe(
      take(1),
      switchMap((tours) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl(`delete/${id}`)).pipe(
          map((response) => {
            const index = tours.data.findIndex((item) => item._id === id);

            tours.data.splice(index, 1);

            this._tours.next(tours);
            return response;
          })
        )
      )
    );
  }

  deleteLocation(locationid: string, tourid: string): Observable<{ message: string; deletedVariant: any }> {
    return this.tour$.pipe(
      take(1),
      switchMap((tour) =>
        this._httpClient.delete<any>(this.generateApiUrl(`location/${tourid}/${locationid}`)).pipe(
          map((response) => {
            const index = tour.locations.findIndex((item) => item._id === locationid);

            tour.locations.splice(index, 1);

            this._tour.next(tour);
            return response;
          })
        )
      )
    );
  }
}
