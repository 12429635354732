import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'auth-side-design',
  templateUrl: './side-design.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideDesignComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
