import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Feed, Comment } from '../models';
import { UserService } from './user.service';

export interface FeedApiResponse {
  data: Feed[];
  meta: {
    total: number;
    limit: number;
    page: number;
    pages: number;
    morePages: boolean;
  };
}
export interface CommentsApiResponse {
  data: Comment[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class FeedService {
  private generateApiUrl = (path: string) => environment.apiURL + 'feeds/' + path;

  public _feed: BehaviorSubject<Feed> = new BehaviorSubject<Feed>(null);
  private _feeds: BehaviorSubject<FeedApiResponse | null> = new BehaviorSubject(null);
  private _comments: BehaviorSubject<CommentsApiResponse | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get feed$(): Observable<Feed> {
    return this._feed.asObservable();
  }

  get feeds$(): Observable<FeedApiResponse> {
    return this._feeds.asObservable();
  }

  get comments$(): Observable<CommentsApiResponse> {
    return this._comments.asObservable();
  }

  getFeed(id: string): Observable<Feed> {
    return this._httpClient.get<Feed>(this.generateApiUrl(`details/${id}`)).pipe(
      tap((response) => {
        this._feed.next(response);
      })
    );
  }

  create(data: any): Observable<{ message: string; feed: Feed }> {
    return this._httpClient.post<{ message: string; feed: Feed }>(this.generateApiUrl('add'), data);
  }

  getAllFeeds(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    pageid = '',
    name = ''
  ): Observable<FeedApiResponse> {
    return this.feeds$.pipe(
      take(1),
      switchMap((feeds) =>
        this._httpClient
          .get<FeedApiResponse>(this.generateApiUrl('fetchall'), {
            params: {
              pageNo,
              size,
              sortBy,
              sortDirection,
              pageid: pageid || this._userService.userObj.page,
              name,
            },
          })
          .pipe(
            tap((response) => {
              if (feeds && response && response.meta.page !== 1) {
                response.data = feeds.data.concat(response.data);
              }
              this._feeds.next(response);
            })
          )
      )
    );
  }

  updateFeed(id: string, data: any): Observable<{ message: string; feed: Feed }> {
    return this._httpClient.put<{ message: string; feed: Feed }>(this.generateApiUrl(`update/${id}`), data).pipe(
      tap((response) => {
        this._feed.next(response.feed);
      })
    );
  }

  likefeed(feedId: string): Observable<{ message: string; isLiked: boolean; likeCount: number }> {
    return this.feeds$.pipe(
      take(1),
      switchMap((feeds) =>
        this._httpClient
          .post<{ message: string; isLiked: boolean; likeCount: number }>(this.generateApiUrl('likefeed'), {
            feedId,
          })
          .pipe(
            map((response) => {
              const index = feeds.data.findIndex((item) => item._id === feedId);
              feeds.data[index].isLiked = response.isLiked;
              feeds.data[index].likeCount = response.likeCount;
              this._feeds.next(feeds);
              return response;
            })
          )
      )
    );
  }

  unlikefeed(feedId: string): Observable<{ message: string; isLiked: boolean; likeCount: number }> {
    return this.feeds$.pipe(
      take(1),
      switchMap((feeds) =>
        this._httpClient
          .post<{ message: string; isLiked: boolean; likeCount: number }>(this.generateApiUrl('unlikefeed'), {
            feedId,
          })
          .pipe(
            map((response) => {
              const index = feeds.data.findIndex((item) => item._id === feedId);
              feeds.data[index].isLiked = response.isLiked;
              feeds.data[index].likeCount = response.likeCount;
              this._feeds.next(feeds);
              return response;
            })
          )
      )
    );
  }

  deleteFeed(id: string) {
    return this.feeds$.pipe(
      take(1),
      switchMap((feeds) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl(`delete/${id}`)).pipe(
          map((response) => {
            const index = feeds.data.findIndex((item) => item._id === id);

            feeds.data.splice(index, 1);

            this._feeds.next(feeds);
            return response;
          })
        )
      )
    );
  }

  shareFeed(id: string, data: any): Observable<{ message: string; feed: Feed }> {
    return this._httpClient.post<{ message: string; feed: Feed }>(this.generateApiUrl(`sharefeed/${id}`), data).pipe(
      tap((response) => {
        this._feed.next(response.feed);
      })
    );
  }

  addVote(
    feedId: string,
    optionId: string,
    actualFeedId?: string
  ): Observable<{ message: string; updatedVoteResult: any }> {
    return this.feeds$.pipe(
      take(1),
      switchMap((feeds) =>
        this._httpClient
          .post<{ message: string; updatedVoteResult: any }>(this.generateApiUrl('addVote'), {
            feedId,
            optionId,
          })
          .pipe(
            map((response) => {
              if (actualFeedId) {
                feedId = actualFeedId;
              }
              const index = feeds.data.findIndex((item) => item._id === feedId);
              if (index !== -1) {
                const feedToUpdate = feeds.data[index];
                if (feedToUpdate) {
                  if (feedToUpdate.type === 'share') {
                    feedToUpdate.feedObj.pollOptions = response.updatedVoteResult.options;
                    feedToUpdate.feedObj.totalVote = response.updatedVoteResult.totalVote;
                    feedToUpdate.feedObj.isVoted = true;
                  } else {
                    feedToUpdate.pollOptions = response.updatedVoteResult.options;
                    feedToUpdate.totalVote = response.updatedVoteResult.totalVote;
                    feedToUpdate.isVoted = true;
                  }
                  this._feeds.next(feeds);
                }
              }
              return response;
            })
          )
      )
    );
  }

  getAllComments(
    post: string,
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string
  ): Observable<CommentsApiResponse> {
    return this.comments$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient
          .get<CommentsApiResponse>(this.generateApiUrl('comments'), {
            params: {
              pageNo,
              size,
              sortBy,
              sortDirection,
              post,
            },
          })
          .pipe(
            map((response) => {
              if (records) {
                response.data = records.data.concat(response.data);
              }
              this._comments.next(response);
              return response;
            })
          )
      )
    );
  }

  addComment(data: { post: string; comment: string }): Observable<{ message: string; comment: Comment }> {
    return this.comments$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient.post<{ message: string; comment: Comment }>(this.generateApiUrl('addComment'), data).pipe(
          map((response) => {
            if (records) {
              records.data.unshift(response.comment);
            } else {
              {
                records = { data: [response.comment], morePages: false, page: 1, totalItems: 1, totalPages: 1 };
              }
            }

            this._comments.next(records);
            return response;
          })
        )
      )
    );
  }

  deleteComment(data: { commentId: string }): Observable<{ message: string }> {
    return this.comments$.pipe(
      take(1),
      switchMap((records) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl('delComment'), { body: data }).pipe(
          map((response) => {
            const index = records.data.findIndex((item) => item._id === data.commentId);

            records.data.splice(index, 1);

            this._comments.next(records);
            return response;
          })
        )
      )
    );
  }

  resetComments() {
    this._comments.next(null);
  }
}
