import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Music, StreamState } from 'app/core/models';
import { AuthService, CustomSnackbarService, MusicService, PlaylistService } from 'app/core/services';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-music-player',
  templateUrl: './music-player.component.html',
  styleUrls: ['./music-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class MusicPlayerComponent implements OnInit, OnDestroy {
  state: StreamState;
  @Input() playMusic: Observable<Music>;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private currentPage = '';

  constructor(
    public _musicService: MusicService,
    public _playlistService: PlaylistService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _customSnackbarService: CustomSnackbarService,
    private _authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentPage = this.router.url;
    // listen to stream state
    this._musicService
      .getState()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((state) => {
        this.state = state;
        this._changeDetectorRef.detectChanges();
      });

    this.playMusic.pipe(takeUntil(this._unsubscribeAll)).subscribe((musicTobePlayed) => {
      this._musicService.currentMusic = {
        music: musicTobePlayed,
        index: this._musicService.musics.findIndex((music) => musicTobePlayed._id === music._id),
      };
      this.playStream();
    });
  }

  playStream() {
    const musicUrl =
      environment.apiURL +
      `music/play/${this._musicService.currentMusic.music.name}?id=${this._musicService.currentMusic.music._id}&token=${this._authService.jwtToken}`;

    this._musicService.stop();
    this._musicService.showPlayer();
    this._musicService.playStream(musicUrl).subscribe();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  pause() {
    this._musicService.pause();
  }

  play() {
    this._musicService.play();
  }

  stop() {
    this._musicService.stop();
  }

  isFirstPlaying() {
    return this._musicService.currentMusic.index === 0;
  }

  isLastPlaying() {
    return this._musicService.currentMusic.index === this._musicService.musics.length - 1;
  }

  next() {
    const newMusicIndex = this._musicService.currentMusic.index + 1;
    this._musicService.currentMusic.music = this._musicService.musics[newMusicIndex];
    this._musicService.currentMusic.index = newMusicIndex;
    this.playStream();
  }

  previous() {
    const newMusicIndex = this._musicService.currentMusic.index - 1;
    this._musicService.currentMusic.music = this._musicService.musics[newMusicIndex];
    this._musicService.currentMusic.index = newMusicIndex;
    this.playStream();
  }

  onSliderChangeEnd(change) {
    this._musicService.seekTo(change.value);
  }

  saveToPlaylist() {
    if (this._musicService.currentMusic) {
      this._playlistService
        .add(this._musicService.currentMusic.music._id, this.currentPage.includes('/playlist'))
        .subscribe(
          (response) => {
            this._customSnackbarService.open(response.message, 'info', 'Ok');
          },
          (error) => {
            this._customSnackbarService.open(error, 'error', 'Ok');
          }
        );
    }
  }

  removeFromPlaylist() {
    this._playlistService
      .remove(this._musicService.currentMusic.music._id, this.currentPage.includes('/playlist'))
      .subscribe(
        (response) => {
          this._customSnackbarService.open(response.message, 'info', 'Ok');
        },
        (error) => {
          this._customSnackbarService.open(error, 'error', 'Ok');
        }
      );
  }
}
