<div class="flex flex-col items-center" *ngIf="page.stripeAccountId; else noStripeAccount">
  <div class="flex flex-col sm:flex-row w-full gap-5">
    <div class="flex flex-col">
      <div class="flex text-base font-medium">Your balance</div>
      <div class="flex text-2xl font-semibold" *ngIf="!isLoading">
        {{ stripeBalanceObj.balanceCurrency
        }}{{ ((stripeBalanceObj.balanceAvailable + stripeBalanceObj.balancePending) / 100).toFixed(2) }}
      </div>
      <div class="flex text-hint" *ngIf="!isLoading">
        {{ stripeBalanceObj.balanceCurrency }}{{ (stripeBalanceObj.balanceAvailable / 100).toFixed(2) }} avaiable
      </div>
      <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" *ngIf="isLoading"> </mat-progress-spinner>
    </div>
    <div class="flex flex-col">
      <button
        mat-raised-button
        color="primary"
        [disabled]="isLoading || stripeBalanceObj.balanceAvailable === 0"
        (click)="payout()"
      >
        Pay out now
      </button>
      <a
        class="font-medium place-self-center"
        [ngClass]="{
          'hover:no-underline text-disabled pointer-events-none cursor-default': isLoading,
          'hover:underline text-primary-500 cursor-pointer': !isLoading
        }"
        (click)="viewPayouts()"
      >
        View payouts on Stripe
      </a>
    </div>
  </div>
</div>

<ng-template #noStripeAccount>
  <div class="w-full md:w-96">
    <span class="flex-wrap text-warn text-lg"> You have not added your bank account yet. </span>
  </div>
</ng-template>
