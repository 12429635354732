import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Product } from '../models';
import { UserService } from './user.service';

export interface ProductsApiResponse {
  data: Product[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private generateApiUrl = (path: string) => environment.apiURL + 'product/' + path;

  public _product: BehaviorSubject<Product> = new BehaviorSubject<Product>(null);
  private _products: BehaviorSubject<ProductsApiResponse | null> = new BehaviorSubject(null);
  private _applicationFee: BehaviorSubject<number | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get product$(): Observable<Product> {
    return this._product.asObservable();
  }

  get products$(): Observable<ProductsApiResponse> {
    return this._products.asObservable();
  }

  get applicationFee$(): Observable<number> {
    return this._applicationFee.asObservable();
  }

  getProduct(id: string): Observable<Product> {
    return this._httpClient.get<Product>(this.generateApiUrl(`single/${id}`)).pipe(
      tap((response) => {
        this._product.next(response);
      })
    );
  }

  create(data: any): Observable<{ message: string; product: Product }> {
    return this._httpClient.post<{ message: string; product: Product }>(this.generateApiUrl('create'), data);
  }

  getAllProducts(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    pageid = '',
    name: string = ''
  ): Observable<ProductsApiResponse> {
    return this._httpClient
      .get<ProductsApiResponse>(this.generateApiUrl('getAll'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          name,
          pageid: pageid || this._userService.userObj.page,
        },
      })
      .pipe(
        tap((response) => {
          this._products.next(response);
        })
      );
  }

  uploadImage(productid: string, data: FormData): Observable<{ message: string; product: Product }> {
    return this._httpClient
      .post<{ message: string; product: Product }>(this.generateApiUrl(`uploadimage/${productid}`), data)
      .pipe(
        tap((response) => {
          this._product.next(response.product);
        })
      );
  }

  deleteImage(data: any): Observable<{ message: string; product: Product }> {
    return this._httpClient.post<{ message: string; product: Product }>(this.generateApiUrl('deleteImage'), data).pipe(
      tap((response) => {
        this._product.next(response.product);
      })
    );
  }

  updateProduct(data: any): Observable<{ message: string; product: Product }> {
    return this._httpClient.post<{ message: string; product: Product }>(this.generateApiUrl('update'), data).pipe(
      tap((response) => {
        this._product.next(response.product);
      })
    );
  }

  deleteVairant(variantid: string, productid: string): Observable<{ message: string; deletedVariant: any }> {
    return this.product$.pipe(
      take(1),
      switchMap((product) =>
        this._httpClient.delete<any>(this.generateApiUrl(`price/${productid}/${variantid}`)).pipe(
          map((response) => {
            const index = product.variants.findIndex((item) => item._id === variantid);

            product.variants.splice(index, 1);

            this._product.next(product);
            return response;
          })
        )
      )
    );
  }

  public deleteProduct(id: string) {
    return this.products$.pipe(
      take(1),
      switchMap((products) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl(`del/${id}`)).pipe(
          map((response) => {
            const index = products.data.findIndex((item) => item._id === id);

            products.data.splice(index, 1);

            this._products.next(products);
            return response;
          })
        )
      )
    );
  }

  activeInactive(productid: string): Observable<any> {
    return this.products$.pipe(
      take(1),
      switchMap((products) =>
        this._httpClient.post(this.generateApiUrl('activeInactive'), { productid }).pipe(
          map((updateData: any) => {
            // Find the index of the updated user
            const index = products.data.findIndex((item) => item._id === productid);

            // Update the user
            products.data[index].isActive = updateData.isActive;

            // Update the users
            this._products.next(products);

            // Return the updated user
            return updateData;
          })
        )
      )
    );
  }

  getApplicationFee(): Observable<number> {
    return this._httpClient.get(environment.apiURL + 'config/applicationFee').pipe(
      tap((applicationFee: number) => {
        this._applicationFee.next(applicationFee);
        return applicationFee;
      })
    );
  }
}
