import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeAPiService {
  private generateApiUrl = (path: string) => environment.apiURL + 'stripe/' + path;

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  fetchAccountLinks(data: { pageid: string; returnUrl: string; refreshUrl: string }): Observable<string> {
    return this._httpClient.post<string>(this.generateApiUrl('accountLinks'), data);
  }

  fetchAccount(accountID: string): Observable<any> {
    return this._httpClient.get<any>(this.generateApiUrl('account/' + accountID));
  }

  getDashboardUrl(pageid: string, returnUrl: string, account = false): Observable<string> {
    return this._httpClient.get<string>(this.generateApiUrl('dashboard'), {
      params: {
        pageid,
        account,
        returnUrl,
      },
    });
  }

  getBalance(): Observable<any> {
    return this._httpClient.get<any>(this.generateApiUrl('balance'));
  }

  payout(): Observable<any> {
    return this._httpClient.post<any>(this.generateApiUrl('payOut'), {});
  }
}
