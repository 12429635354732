<!-- Music player here -->
<div
  class="relative flex flex-row bottom-0 border-b sm:border-t sm:border-b-0 z-10 bg-accent-100 dark:bg-transparent p-2"
  *ngIf="state.showPlayer"
  @zoomIn
>
  <!-- Dismiss button -->
  <ng-container>
    <div class="absolute top-0 right-0 pr-4">
      <button mat-icon-button (click)="_musicService.hidePlayer()">
        <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
      </button>
    </div>
  </ng-container>

  <!-- Song info -->
  <div class="flex-row w-1/4 justify-center items-center text-black hidden sm:flex">
    <!-- Image -->
    <div class="items-center flex">
      <div class="relative flex flex-0 items-center justify-center w-16 h-16 mr-6 rounded overflow-hidden border">
        <img class="object-cover h-full" [alt]="'Product thumbnail image'" [src]="'assets/images/5uwq.gif'" />
        <!-- <div
            class="
              flex
              items-center
              justify-center
              w-full
              h-full
              text-xs
              font-semibold
              leading-none
              text-center
              uppercase
            "
          >
            NO IMAGE
          </div> -->
      </div>
    </div>

    <span class="font-medium break-words">{{ _musicService.currentMusic.music.name }}</span>
    <button
      mat-icon-button
      color="primary"
      *ngIf="!_musicService.currentMusic.music.isSavedInPlaylist; else solidHeart"
      (click)="saveToPlaylist()"
    >
      <mat-icon [svgIcon]="'heroicons_outline:heart'" class="icon-size-7"></mat-icon>
    </button>
    <ng-template #solidHeart>
      <button mat-icon-button color="primary" (click)="removeFromPlaylist()">
        <mat-icon [svgIcon]="'heroicons_solid:heart'" class="icon-size-7"></mat-icon>
      </button>
    </ng-template>
  </div>

  <!-- Player control -->
  <div class="flex flex-col w-full sm:w-1/2 text-black justify-center items-center">
    <!-- Play, pause, seek buttons -->
    <div class="flex flex-row justify-center items-center w-full gap-3">
      <button mat-icon-button color="primary" [disabled]="isFirstPlaying()" (click)="previous()">
        <mat-icon [svgIcon]="'mat_solid:skip_previous'" class="icon-size-7"></mat-icon>
      </button>

      <button
        mat-fab
        class="rounded-xl bg-white shadow-lg text-primary"
        (click)="play()"
        [disabled]="state?.error"
        *ngIf="!state?.playing"
      >
        <mat-icon [svgIcon]="'mat_solid:play_circle_filled'" class="icon-size-10"></mat-icon>
      </button>

      <button mat-fab class="rounded-xl bg-white shadow-lg text-primary" (click)="pause()" *ngIf="state?.playing">
        <mat-icon [svgIcon]="'mat_solid:pause_circle_filled'" class="icon-size-10"></mat-icon>
      </button>

      <button mat-icon-button color="primary" [disabled]="isLastPlaying()" (click)="next()">
        <mat-icon [svgIcon]="'mat_solid:skip_next'" class="icon-size-7"></mat-icon>
      </button>
    </div>

    <!-- Slider -->
    <div class="flex flex-row justify-center items-center w-full">
      {{ state?.readableCurrentTime }}
      <mat-slider
        min="0"
        [max]="state?.duration"
        step="1"
        [value]="state?.currentTime"
        (input)="onSliderChangeEnd($event)"
        [disabled]="state?.error || _musicService.currentMusic === undefined"
        class="w-full sm:w-1/2 text-primary"
      ></mat-slider>
      {{ state?.readableDuration }}
    </div>

    <div class="flex font-medium break-words sm:hidden">{{ _musicService.currentMusic.music.name }}</div>
  </div>

  <!-- Action buttons -->
  <!-- <div class="flex flex-row w-1/4">
      <span class="font-medium">asdkjnalskdasd</span>
    </div> -->
</div>
