import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Support } from '../models';

export interface SupportApiResponse {
  data: Support[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private generateApiUrl = (path: string) => environment.apiURL + 'support/' + path;

  public _support: BehaviorSubject<Support> = new BehaviorSubject<Support>(null);
  private _supports: BehaviorSubject<SupportApiResponse | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get support$(): Observable<Support> {
    return this._support.asObservable();
  }

  get supports$(): Observable<SupportApiResponse> {
    return this._supports.asObservable();
  }

  create(data: any): Observable<{ message: string; support: Support }> {
    return this._httpClient.post<{ message: string; support: Support }>(this.generateApiUrl('create'), data);
  }

  getAll(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    name = ''
  ): Observable<SupportApiResponse> {
    return this._httpClient
      .get<SupportApiResponse>(this.generateApiUrl('getAll'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          name,
        },
      })
      .pipe(
        tap((response) => {
          this._supports.next(response);
          return response;
        })
      );
  }

  public delete(data: { id: string }) {
    return this.supports$.pipe(
      take(1),
      switchMap((record) =>
        this._httpClient.delete<{ message: string }>(this.generateApiUrl('del'), { body: data }).pipe(
          map((response) => {
            const index = record.data.findIndex((item) => item._id === data.id);

            record.data.splice(index, 1);
            if (record.data.length === 0) {
              record.totalItems = 0;
              record.totalPages = 0;
            }

            this._supports.next(record);
            return response;
          })
        )
      )
    );
  }
}
