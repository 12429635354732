import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule {
  /**
   * Constructor
   */
  constructor(private _domSanitizer: DomSanitizer, private _matIconRegistry: MatIconRegistry) {
    // Register icon sets
    this._matIconRegistry.addSvgIconSet(
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'mat_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'iconsmind',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'feather',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_outline',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'heroicons_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome_regular',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fontawesome-regular.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fontawesome-solid.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome_light',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fontawesome-light.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome_brand',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fontawesome-brands.svg')
    );
    // Fontawesome6 Pro Icons. Taken from https://github.com/tallesairan/FA5PRO/tree/fa6/sprites
    // Updated from https://github.com/ttungbmt/fontawesome-pro/tree/main/sprites
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome6_brand',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa6-brands.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome6_regular',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa6-regular.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome6_solid',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa6-solid.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome6_duotone',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa6-duotone.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome6_light',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa6-light.svg')
    );
    this._matIconRegistry.addSvgIconSetInNamespace(
      'fontawesome6_thin',
      this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fa6-thin.svg')
    );
  }
}
