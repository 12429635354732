import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { CustomSnackbarService } from 'app/core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageUploaderComponent implements OnInit, OnDestroy {
  @Input() isUploadingImage: boolean = false;
  @Output() uploadEvent: EventEmitter<any> = new EventEmitter();
  @Input() resetUploader: EventEmitter<boolean>;

  selectedFiles: any;
  selectedFileNames: string[] = [];
  previews: string[] = [];

  private _unsubscribeAll: Subject<any> = new Subject();

  constructor(private _customSnackbarService: CustomSnackbarService, private _changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.resetUploader.pipe(takeUntil(this._unsubscribeAll)).subscribe((isClear) => {
      if (isClear) {
        this.selectedFiles = null;
        this.previews = [];
        this.selectedFileNames = [];
      }
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  selectFiles(event: any): void {
    this.selectedFileNames = [];
    this.selectedFiles = event.target.files;
    this.previews = [];

    if (this.selectedFiles && this.selectedFiles[0]) {
      this.selectedFiles = [...this.selectedFiles];

      let numberOfFiles = this.selectedFiles.length;

      if (numberOfFiles > 5) {
        this.selectedFiles = this.selectedFiles.slice(0, 5);
        numberOfFiles = 5;
        this._customSnackbarService.open('Maximum 5 files allowed to upload. Truncating files...', 'error', 'Ok');
      }
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.previews.push(e.target.result);
        };
        reader.readAsDataURL(this.selectedFiles[i]);
        this._changeDetectorRef.detectChanges();
        this.selectedFileNames.push(this.selectedFiles[i].name);
      }
    }
  }

  public uploadFiles() {
    this.uploadEvent.emit(this.selectedFiles);
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
