<div class="relative flex flex-col md:w-128 -m-6">
  <!-- Dismiss button -->
  <div class="absolute top-0 right-0 pt-4 pr-4">
    <button mat-icon-button [matDialogClose]="undefined">
      <mat-icon class="text-secondary" [svgIcon]="'heroicons_outline:x'"></mat-icon>
    </button>
  </div>

  <!-- Content -->
  <div class="flex flex-col sm:flex-row items-center sm:items-start p-8 pb-6 sm:pb-8">
    <div
      class="flex flex-col gap-5 items-center sm:items-start mt-4 sm:mt-0 sm:pr-8 space-y-1 text-center sm:text-left"
    >
      <!-- Title -->
      <div class="text-2xl leading-6 font-semibold" [innerHTML]="title"></div>

      <!-- Message -->
      <div class="text-black text-lg" [innerHTML]="message"></div>
    </div>
  </div>

  <!-- Actions -->
  <ng-container>
    <div
      class="
        flex
        items-center
        justify-center
        sm:justify-end
        px-6
        py-4
        space-x-3
        bg-gray-50
        dark:bg-black dark:bg-opacity-10
      "
    >
      <!-- Close -->
      <ng-container>
        <button mat-stroked-button [matDialogClose]="'closed'">Close</button>
      </ng-container>
    </div>
  </ng-container>
</div>
