import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Video } from '../models';
import { UserService } from './user.service';

export interface VideosApiResponse {
  data: Video[];
  totalPages: number;
  totalItems: number;
}

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private generateApiUrl = (path: string) => environment.apiURL + 'link/' + path;

  public _video: BehaviorSubject<Video> = new BehaviorSubject<Video>(null);
  private _videos: BehaviorSubject<VideosApiResponse | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _userService: UserService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get video$(): Observable<Video> {
    return this._video.asObservable();
  }

  get videos$(): Observable<VideosApiResponse> {
    console.log(this._videos);

    return this._videos.asObservable();
  }

  getVideo(id: string): Observable<Video> {
    return this._httpClient.get<Video>(this.generateApiUrl(`single/${id}`)).pipe(
      tap((response) => {
        this._video.next(response);
      })
    );
  }

  create(data: any): Observable<{ message: string; video: Video }> {
    return this._httpClient.post<{ message: string; video: Video }>(this.generateApiUrl('add'), data);
  }

  getAllVideos(
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string,
    pageid = '',
    name = ''
  ): Observable<VideosApiResponse> {
    console.log('this function');
    return this._httpClient
      .get<VideosApiResponse>(this.generateApiUrl('fetchall'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          pageid: pageid || this._userService.userObj.page,
          name,
        },
      })
      .pipe(
        tap((response) => {
          this._videos.next(response);
        })
      );
  }

  updateVideo(data: any, id: string): Observable<{ message: string; video: Video }> {
    return this._httpClient.put<{ message: string; video: Video }>(this.generateApiUrl(`update/${id}`), data).pipe(
      tap((response) => {
        this._video.next(response.video);
      })
    );
  }

  updateVideoStatus(data: any): Observable<{ message: string; isActive: boolean }> {
    return this.videos$.pipe(
      take(1),
      switchMap((videos) =>
        this._httpClient.post<{ message: string; isActive: boolean }>(this.generateApiUrl('activeInactive'), data).pipe(
          map((updateData: any) => {
            // Find the index of the updated user
            const index = videos.data.findIndex((item) => item._id === data.videoid);

            videos.data[index].isActive = updateData.isActive;

            this._videos.next(videos);

            return updateData;
          })
        )
      )
    );
  }

  deleteVideo(id: string) {
    return this._httpClient.delete<{ message: string; video: Video }>(this.generateApiUrl(`delete/${id}`)).pipe(
      tap((response) => {
        this._video.next(response.video);
      })
    );
  }
}
