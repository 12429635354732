import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Task } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  // Private
  private generateApiUrl = (path: string) => environment.apiURL + 'tasks/' + path;
  _task: BehaviorSubject<Task | null> = new BehaviorSubject(null);
  private _tasks: BehaviorSubject<Task[] | null> = new BehaviorSubject(null);
  _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for task
   */
  get task$(): Observable<Task> {
    return this._task.asObservable();
  }

  /**
   * Getter for tasks
   */
  get tasks$(): Observable<Task[]> {
    return this._tasks.asObservable();
  }

  get isLoading(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get tasks
   */
  getTasks(): Observable<Task[]> {
    return this._httpClient.get<Task[]>(this.generateApiUrl('all')).pipe(
      tap((response) => {
        this._tasks.next(response);
      })
    );
  }

  /**
   * Get task by id
   */
  getTaskById(id: string): Observable<Task> {
    if (id === 'new') {
      return of(null);
    }
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.get<Task>(this.generateApiUrl(`task/${id}`)).pipe(
          map((task) => {
            // Find the index of the updated task
            const index = tasks.findIndex((item) => item._id === id);

            // Update the task
            tasks[index] = task;

            // Update the tasks
            this._tasks.next(tasks);
            this._task.next(task);

            // Return the updated task
            return task;
          })
        )
      )
    );
  }

  /**
   * Create task
   *
   * @param task
   */
  createTask(task: { title: string; notes: string }): Observable<{ task: Task; message: string }> {
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.post<{ task: Task; message: string }>(this.generateApiUrl('create'), task).pipe(
          map((newTask) => {
            // Update the tasks with the new task
            this._tasks.next([newTask.task, ...tasks]);

            // Return the new task
            return newTask;
          })
        )
      )
    );
  }

  /**
   * Update task
   *
   * @param id
   * @param data
   */
  updateTask(
    id: string,
    data: { title: string; notes: string; completed: boolean }
  ): Observable<{ task: Task; message: string }> {
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient
          .patch<{ task: Task; message: string }>(this.generateApiUrl('task'), {
            ...data,
            taskId: id,
          })
          .pipe(
            map((updatedTask) => {
              // Find the index of the updated task
              const index = tasks.findIndex((item) => item._id === id);

              // Update the task
              tasks[index] = updatedTask.task;

              // Update the tasks
              this._tasks.next(tasks);

              // Return the updated task
              return updatedTask;
            }),
            switchMap((updatedTask) =>
              this.task$.pipe(
                take(1),
                map((item) => {
                  if (item && item._id === id) {
                    // Update the task if it's selected
                    this._task.next(updatedTask.task);
                  }

                  // Return the updated task
                  return updatedTask;
                })
              )
            )
          )
      )
    );
  }

  /**
   * Delete the task
   *
   * @param id
   */
  deleteTask(id: string): Observable<{ taskId: string; message: string }> {
    return this.tasks$.pipe(
      take(1),
      switchMap((tasks) =>
        this._httpClient.delete<{ taskId: string; message: string }>(this.generateApiUrl(`task/${id}`)).pipe(
          map((response) => {
            // Find the index of the deleted task
            const index = tasks.findIndex((item) => item._id === id);

            // Delete the task
            tasks.splice(index, 1);

            // Update the tasks
            this._tasks.next(tasks);

            // Return the deleted status
            return response;
          })
        )
      )
    );
  }
}
