import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Donate } from '../models';

export interface DonationListApiResponse {
  data: Donate[];
  totalPages: number;
  totalItems: number;
  morePages: boolean;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class DonationService {
  private generateApiUrl = (path: string) => environment.apiURL + 'donate/' + path;

  private _donationList: BehaviorSubject<DonationListApiResponse | null> = new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get donationList$(): Observable<DonationListApiResponse> {
    return this._donationList.asObservable();
  }

  create(data: { amount: number; comments: string; pageid: string }): Observable<{
    message: string;
    donate: Donate;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    client_secret: string;
  }> {
    return this._httpClient.post<{
      message: string;
      donate: Donate;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_secret: string;
    }>(this.generateApiUrl('create'), data);
  }

  updateStatus(data: { status: 'succeeded' | 'failed'; donateID: string }): Observable<{ message: string }> {
    return this._httpClient.post<{ message: string }>(this.generateApiUrl('updateStatus'), data);
  }

  getDonationList(
    pageid: string,
    pageNo: number,
    size: number,
    sortBy: string,
    sortDirection: string
  ): Observable<DonationListApiResponse> {
    return this._httpClient
      .get<DonationListApiResponse>(this.generateApiUrl('list'), {
        params: {
          pageNo,
          size,
          sortBy,
          sortDirection,
          pageid,
        },
      })
      .pipe(
        tap((response) => {
          this._donationList.next(response);
        })
      );
  }
}
