<!-- Tabs -->
<mat-tab-group [animationDuration]="'0'" class="mt-5" #mytab>
  <!-- Tab #1 -->
  <mat-tab label="Cards">
    <!-- TAB CONTENT GOES HERE -->
    <!-- <div class="flex justify-center"> -->
    <ngx-stripe-card
      [options]="cardOptions"
      [elementsOptions]="elementsOptions"
      (change)="onChange($event)"
    ></ngx-stripe-card>
    <div id="card-errors" class="mt-3 font-medium text-warn" role="alert"></div>
    <!-- </div> -->
  </mat-tab>

  <!-- Tab #2 -->
  <mat-tab label="Wallets" [disabled]="'true'">
    <ng-template mat-tab-label>
      <label matTooltip="Comming Soon" matTooltipClass="tooltip-size">Wallets </label>
    </ng-template>
    <!-- TAB CONTENT GOES HERE -->
    <div class="flex flex-col justify-center items-center gap-2"></div>
  </mat-tab>
</mat-tab-group>
