import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { User } from 'app/core/models/user.model';
import { Page } from '../models';
import { environment } from 'environments/environment';
import { Address } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _userAddresses: BehaviorSubject<Address[]> = new BehaviorSubject<Address[]>(null);
  private _forgotPassToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  set user(value: any) {
    this.userSubject.next(value);
  }

  get forgotPassToken$(): Observable<string> {
    return this._forgotPassToken.asObservable();
  }

  public get user$(): Observable<User> {
    return of(this.userSubject.value?.user);
  }

  public get userObj(): User {
    return this.userSubject.value?.user;
  }

  public get pageObj(): Page {
    return this.userSubject.value?.page;
  }

  get userSubjectValue() {
    return this.userSubject.value;
  }

  get userSubject$(): Observable<any> {
    return this.userSubject.asObservable();
  }

  get userAddresses$(): Observable<Address[]> {
    return this._userAddresses.asObservable();
  }

  getCurrentUser(): Observable<User> {
    return this.userSubject.pipe(
      take(1),
      switchMap((userDetailsAfterSignin: any) =>
        this._httpClient.get<{ user: User }>(environment.apiURL + 'users/me').pipe(
          map((currentUser: any) => {
            userDetailsAfterSignin.user = currentUser.user;
            userDetailsAfterSignin.page = currentUser.page;

            this.userSubject.next(userDetailsAfterSignin);

            return currentUser.user;
          })
        )
      )
    );
  }

  getAddress(id: string) {
    return this._httpClient.get<Address>(environment.apiURL + 'users/address/' + id);
  }

  getAllAddress() {
    return this._httpClient.get<Address[]>(environment.apiURL + 'users/address').pipe(
      tap((addresses) => {
        this._userAddresses.next(addresses);
        return addresses;
      })
    );
  }

  addNewAddress(data: any) {
    return this.userAddresses$.pipe(
      take(1),
      switchMap((allAddress) =>
        this._httpClient.post<{ address: Address; message: string }>(environment.apiURL + 'users/address', data).pipe(
          map((newAddress) => {
            this._userAddresses.next([newAddress.address, ...allAddress]);

            return newAddress;
          })
        )
      )
    );
  }

  editAddress(id: string, data: any) {
    return this.userAddresses$.pipe(
      take(1),
      switchMap((allAddress) =>
        this._httpClient
          .put<{ address: Address; message: string }>(environment.apiURL + `users/address/${id}`, data)
          .pipe(
            map((updateData) => {
              const index = allAddress.findIndex((item) => item._id === id);

              allAddress[index] = updateData.address;

              this._userAddresses.next(allAddress);

              return updateData;
            })
          )
      )
    );
  }

  public deleteAddress(id: string) {
    return this.userAddresses$.pipe(
      take(1),
      switchMap((allAddress) =>
        this._httpClient
          .delete<{ address: Address; message: string }>(environment.apiURL + 'users/address/delete/' + id)
          .pipe(
            map((response) => {
              const index = allAddress.findIndex((item) => item._id === id);

              allAddress.splice(index, 1);

              this._userAddresses.next(allAddress);
              return response;
            })
          )
      )
    );
  }

  changePassword(data: { oldPassword: string; newPassword: string }) {
    return this._httpClient.post<{ message: string }>(environment.apiURL + 'users/changePassword', data);
  }

  forgotPassSendOtp(data: { email: string }) {
    return this._httpClient
      .post<{ message: string; token?: string }>(environment.apiURL + 'users/forgotPass/sendOtp', data)
      .pipe(
        tap((response) => {
          if (response.token) {
            this._forgotPassToken.next(response.token);
          }
          return response;
        })
      );
  }

  forgotPassReSendOtp(token: string) {
    return this._httpClient.get<any>(environment.apiURL + 'users/forgotPass/resendOtp', {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  forgotPassVerifyOtp(value: string, token: string) {
    return this._httpClient.post<any>(
      environment.apiURL + 'users/forgotPass/verifyOtp',
      { value },
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
  }

  forgotPassChangePass(password: string, token: string) {
    return this._httpClient.post<any>(environment.apiURL + 'users/forgotPass/changePass', password, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }

  resetForgotPassToken() {
    this._forgotPassToken.next(null);
  }
}
