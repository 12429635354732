import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { io, Socket } from 'socket.io-client';
import { Event } from '../models/socketEvent';

@Injectable()
export class SocketService {
  private socket: Socket;

  constructor() {}

  public initSocket(currentUserToken: string): void {
    this.socket = io(`${environment.apiURL.slice(0, -1)}`, {
      extraHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${currentUserToken}`,
      },
      reconnectionDelayMax: 10000,
      reconnectionAttempts: 5,
    });
  }

  public disconnect(): void {
    this.socket?.disconnect();
  }

  public emit(data: any, event: Event): void {
    this.socket.emit(event, data);
  }

  public onEvent(event: Event): Observable<any> {
    return new Observable<Event>((observer) => {
      this.socket.on(event, (t) => observer.next(t));
    });
  }
}
