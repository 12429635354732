import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'app/core/models';
import { StripeAPiService, PageService, CustomSnackbarService } from 'app/core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'stripe-balance',
  templateUrl: './stripe-balance.component.html',
  styleUrls: ['./stripe-balance.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeBalanceComponent implements OnInit, OnDestroy {
  stripeBalanceObj: any = '';
  page: Page;
  isLoading: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _stripeApiService: StripeAPiService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _pageService: PageService,
    private _customSnackbarService: CustomSnackbarService,
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this._pageService.page$.pipe(takeUntil(this._unsubscribeAll)).subscribe((page) => {
      this.page = page;
      if (page.isOwner && page.stripeAccountId) {
        this.fetchStripeBalance();
      }

      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  fetchStripeBalance() {
    this.isLoading = true;
    this._changeDetectorRef.markForCheck();
    this._stripeApiService.getBalance().subscribe(
      (balance) => {
        this.stripeBalanceObj = balance;
        this.isLoading = false;

        this._changeDetectorRef.markForCheck();
      },
      (error) => {
        console.error('Error fetching stripe account details: ', error);
        this.stripeBalanceObj = '';
        this.isLoading = false;

        this._changeDetectorRef.markForCheck();
      }
    );
  }

  viewStripeAccount() {
    if (!this.page || !this.page.stripeAccountId) {
      this._customSnackbarService.open('Stripe account is not linked', 'error', 'Ok');
    }
    this.isLoading = true;
    this._changeDetectorRef.markForCheck();
    this._stripeApiService.getDashboardUrl(this.page._id, this._router.url, true).subscribe(
      (url) => {
        this.document.location.href = url;

        this.isLoading = false;

        this._changeDetectorRef.markForCheck();
      },
      (err) => {
        this._customSnackbarService.open(err, 'error', 'Ok');

        this.isLoading = false;

        this._changeDetectorRef.markForCheck();
      }
    );
  }

  viewPayouts() {
    if (!this.page || !this.page.stripeAccountId) {
      this._customSnackbarService.open('Stripe account is not linked', 'error', 'Ok');
    }
    this.isLoading = true;
    this._changeDetectorRef.markForCheck();
    this._stripeApiService.getDashboardUrl(this.page._id, this._router.url).subscribe(
      (url) => {
        this.document.location.href = url;

        this.isLoading = false;

        this._changeDetectorRef.markForCheck();
      },
      (err) => {
        this._customSnackbarService.open(err, 'error', 'Ok');

        this.isLoading = false;

        this._changeDetectorRef.markForCheck();
      }
    );
  }

  payout() {
    this.isLoading = true;

    this._stripeApiService.payout().subscribe(
      (resp) => {
        this.isLoading = false;
        this._customSnackbarService.open(resp.message, 'info', 'Ok');
        this.fetchStripeBalance();
      },
      (err) => {
        this._customSnackbarService.open(err, 'error', 'Ok');

        this.isLoading = false;

        this._changeDetectorRef.markForCheck();
      }
    );
  }
}
