<ng-container *ngIf="buttonStyle === 'flat'">
  <button
    class="fuse-mat-button-rounded w-full"
    mat-flat-button
    [color]="'primary'"
    *ngIf="_navigatorShareService.canShare(); else customShare"
    (click)="webApiShare()"
  >
    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:share'"></mat-icon>
    <span class="ml-2">{{ buttonText }}</span>
  </button>
  <ng-template #customShare>
    <button
      class="fuse-mat-button-rounded w-full"
      mat-flat-button
      [color]="'primary'"
      #popoverTrigger="mtxPopoverTrigger"
      [mtxPopoverTriggerFor]="popover"
      mtxPopoverTriggerOn="click"
    >
      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:share'"></mat-icon>
      <span class="ml-2">{{ buttonText }}</span>
    </button>
  </ng-template>
</ng-container>

<ng-container *ngIf="buttonStyle === 'stroked'">
  <button
    class="fuse-mat-button-rounded"
    mat-stroked-button
    *ngIf="_navigatorShareService.canShare(); else customShare"
    (click)="webApiShare()"
  >
    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:share'"></mat-icon>
    <span class="ml-2">{{ buttonText }}</span>
  </button>
  <ng-template #customShare>
    <button
      class="fuse-mat-button-rounded"
      mat-stroked-button
      #popoverTrigger="mtxPopoverTrigger"
      [mtxPopoverTriggerFor]="popover"
      mtxPopoverTriggerOn="click"
    >
      <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:share'"></mat-icon>
      <span class="ml-2">{{ buttonText }}</span>
    </button>
  </ng-template>
</ng-container>

<mtx-popover #popover="mtxPopover" [closeOnBackdropClick]="true">
  <div class="max-w-80">
    <share-buttons
      [include]="['facebook', 'twitter', 'whatsapp', 'telegram', 'linkedin', 'messenger', 'copy']"
      [show]="7"
      [showText]="false"
      [url]="shareCurrentPage ? currentPageFullUrl : currentOriginUrl"
    ></share-buttons>

    <!-- <button mat-fab shareButton="facebook" #fbBtn="shareButton" [style.backgroundColor]="fbBtn?.color">
      <mat-icon [svgIcon]="'fontawesome_brand:facebook-f'" class="icon-size-5"></mat-icon>
    </button>
    <button mat-fab shareButton="twitter" #twtBtn="shareButton" [style.backgroundColor]="twtBtn?.color">
      <mat-icon [svgIcon]="'fontawesome_brand:twitter'" class="icon-size-5"></mat-icon>
    </button>
    <button mat-fab shareButton="linkedin" #linkedinBtn="shareButton" [style.backgroundColor]="linkedinBtn?.color">
      <mat-icon [svgIcon]="'fontawesome_brand:linkedin-in'" class="icon-size-5"></mat-icon>
    </button>
    <button mat-fab shareButton="whatsapp" #whatsappBtn="shareButton" [style.backgroundColor]="whatsappBtn?.color">
      <mat-icon [svgIcon]="'fontawesome_brand:whatsapp'" class="icon-size-5"></mat-icon>
    </button>
    <button mat-fab shareButton="telegram" #telegramBtn="shareButton" [style.backgroundColor]="telegramBtn?.color">
      <mat-icon [svgIcon]="'fontawesome_brand:telegram-plane'" class="icon-size-5"></mat-icon>
    </button>
    <button mat-fab shareButton="copy" #copyBtn="shareButton" [style.backgroundColor]="copyBtn?.color">
      <mat-icon [svgIcon]="'fontawesome_regular:copy'" class="icon-size-5"></mat-icon>
    </button> -->
  </div>
</mtx-popover>
