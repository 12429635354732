import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { UserService } from './user.service';
import { EMPTY, Observable } from 'rxjs';
import { User } from '../models';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from './local-storage.service';
import { MusicService } from '.';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private _userService: UserService,
    private _cookieService: CookieService,
    private _musicService: MusicService,
    private _localStorageService: LocalStorageService
  ) {}

  public get jwtToken(): string {
    return this._userService.userSubjectValue?.token;
  }

  signIn(credentials: { email: string; password: string }) {
    return this.http.post<any>(`${environment.apiURL}auth/signin`, credentials, { withCredentials: true }).pipe(
      map((user) => {
        this._userService.user = user;
        this._localStorageService.savePageId(user.user.page);
        this._musicService.hidePlayer();
        this.startRefreshTokenTimer();
        return user;
      })
    );
  }

  signUp(userForm: any): Observable<{ user: User; message: string }> {
    if (userForm.hasOwnProperty('passwordConfirm')) {
      // Remove the passwordConfirm field
      delete userForm.passwordConfirm;
    }
    return this.http.post<{ user: User; message: string }>(`${environment.apiURL}auth/signup`, userForm);
  }

  signOut() {
    this.http
      .post<any>(`${environment.apiURL}auth/revokeToken`, {})
      .subscribe(/*() => this._cookieService.delete('refreshToken')*/);
    this.stopRefreshTokenTimer();
    this._userService.user = null;
  }

  refreshToken() {
    // eslint-disable-next-line max-len
    const currentRoute = window.location.pathname; // This isnt the elegant way to do this, cause in SSR we will not get window, but for now as we are not using SSR so why not use this
    if (currentRoute === '/termsandcondition' || currentRoute === '/privacypolicy' || currentRoute === '/aboutus') {
      return EMPTY;
    }
    return this.http.post<any>(`${environment.apiURL}auth/refreshToken`, {}, { withCredentials: true }).pipe(
      map((user) => {
        this._userService.user = user;
        this._localStorageService.savePageId(user.user.page);
        this.startRefreshTokenTimer();
        return user;
      })
    );
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
