<div class="flex flex-col">
  <!-- <a href="javascript:void(0);" class="stripe-connect" (click)="connectWithStripe()">
    <span>Connect with</span>
  </a>
  <mat-hint class="flex-wrap">To receive payments please add your bank account with stripe.</mat-hint> -->

  <ng-template #connect>
    <div class="flex flex-col w-52">
      <div>
        <button
          mat-raised-button
          class="text-white flex flex-row"
          (click)="connectWithStripe()"
          [disabled]="isLoading"
          [ngClass]="{ 'mat-button-disabled': isLoading, 'hover:bg-stripe-button-hover bg-stripe-button': !isLoading }"
          matTooltip="To receive payments please add your bank account / card details with stripe."
          matTooltipClass="tooltip-size"
        >
          <div class="mr-1" *ngIf="isLoading">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
          </div>
          <div class="flex flex-row items-center">
            <span>Connect with</span>
            <img src="assets/images/logo/stripe.svg" alt="stripe-logo" class="h-8" />
          </div>
        </button>
      </div>
      <div class="flex sm:hidden">
        <mat-hint class="flex-wrap">
          To receive payments please add your bank account / card details with stripe.
        </mat-hint>
      </div>
    </div>
  </ng-template>

  <div
    class="flex flex-col w-full md:flex-row justify-center md:items-center gap-1"
    *ngIf="
      stripeAccountDetails && (stripeAccountDetails.charges_enabled || stripeAccountDetails.details_submitted);
      else connect
    "
  >
    <div class="w-full md:w-96">
      <span class="flex-wrap text-hint order-last md:order-first">
        {{
          stripeAccountDetails.charges_enabled
            ? 'You have added account details with stripe. To manage account click stripe button to go to stripe dashboard'
            : 'Yuur details have been submitted, but account payment verification is under process, please click on the stripe button and see your payment details.'
        }}
      </span>
    </div>

    <div class="w-52 order-first md:order-last">
      <button
        mat-raised-button
        class="text-white flex flex-row"
        (click)="viewStripeAccount()"
        [disabled]="isLoading"
        [ngClass]="{ 'mat-button-disabled': isLoading, 'hover:bg-stripe-button-hover bg-stripe-button': !isLoading }"
      >
        <div class="mr-1">
          <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" *ngIf="isLoading"> </mat-progress-spinner>
          <mat-icon
            [svgIcon]="
              stripeAccountDetails.charges_enabled ? 'heroicons_outline:check' : 'heroicons_outline:information-circle'
            "
            *ngIf="!isLoading"
          ></mat-icon>
        </div>

        <div class="flex flex-row items-center">
          <span>View</span>
          <img src="assets/images/logo/stripe.svg" alt="stripe-logo" class="h-7" />
          <span>account</span>
        </div>
      </button>
    </div>
  </div>
</div>
